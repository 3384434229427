const state = function() {
  return {
    accessToken: undefined,
    expiresAt: undefined,
    profile: undefined
  };
};

const getters = {
  tokenExpiration: function(state) {
    if (!state.expiresAt) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(state.expiresAt);
    return date;
  },

  surname: function(state) {
    return state.profile["surname"];
  },

  givenName: function(state) {
    return state.profile["given_name"];
  },

  patientCode: function(state, getters, rootState) {
    const studyName = rootState.currentStudy?.name;
    return studyProps(state, studyName)?.patientIds?.[0];
  },

  role: function(state, getters, rootState) {
    const studyName = rootState.currentStudy?.name;
    return studyProps(state, studyName)?.role?.split(":")?.[0];
  },

  workflow: function(state, getters, rootState) {
    const studyName = rootState.currentStudy?.name;
    return studyProps(state, studyName)?.role;
  },

  title: function(state) {
    return state.profile["title"];
  },

  phone: function(state) {
    return state.profile["phone"];
  },

  email: function(state) {
    return state.profile["email"];
  },

  userid: function(state) {
    return state.profile["userid"];
  },

  id: function(state) {
    return state.profile["sub"];
  },

  email_verified: function(state) {
    return state.profile["email_verified"];
  },

  sites: function(state, getters, rootState) {
    const studyName = rootState.currentStudy?.name;
    return studyProps(state, studyName)?.sites;
  },

  patientIds: function(state, getters, rootState) {
    const studyName = rootState.currentStudy?.name;
    return studyProps(state, studyName)?.patientIds;
  },

  studies: function(state) {
    return state.profile["studies"];
  },

  /**
   * This getter returns an receive on ore more roles and return true if the connected user has one of those roles.
   */
  isInRole: function(state, getters) {
    return (...roles) => {
      if (
        process.env.VUE_APP_ENABLE_SUPERUSER == "false" ||
        !process.env.VUE_APP_ENABLE_SUPERUSER
      )
        return roles.filter(r => r != "developer").includes(getters.role);
      return roles.includes(getters.role);
    };
  },

  isTokenExpired: function(state, getters) {
    return getters.tokenExpiration < new Date();
  },

  isLoggedIn: function(state, getters) {
    if (!state.profile) return false;
    return !getters.isIdTokenExpired;
  }
};

const mutations = {
  setProfile: function(state, profile) {
    state.profile = profile;
  },
  setAccessToken: function(state, accessToken) {
    state.accessToken = accessToken;
  },
  setExpiresAt: function(state, expiresAt) {
    state.expiresAt = expiresAt;
  },
  clearAccessToken: function() {
    this.commit("auth/setProfile", "");
    this.commit("auth/setAccessToken", "");
    this.commit("auth/setExpiresAt", "");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};

function studyProps({ profile }, studyName) {
  if (!profile["studies"] || !studyName) return undefined;
  return typeof profile["studies"] == "string"
    ? JSON.parse(profile["studies"])[studyName]
    : profile["studies"][studyName];
}
