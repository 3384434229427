import store from "../store/index.js";
import { mapGetters } from "vuex";
import mapBind from "../mapbind.js";

const { patientCode, role } = mapBind(store, {
  ...mapGetters("auth", ["patientCode", "role"])
});

export function startPageForRole(to) {
  if (role() == "patient") {
    switch (to.name) {
      case "Edit patient":
      case "Patient CRF":
        if (to.params.app != "epro" || to.params.patientCode != patientCode())
          return {
            name: to.name,
            query: { ...to.query },
            params: { ...to.params, app: "epro", patientCode: patientCode() }
          };
        break;
      default:
        return {
          name: "Patient CRF",
          query: { ...to.query },
          params: { ...to.params, app: "epro", patientCode: patientCode() }
        };
    }
  } else if (["investigator"].includes(role())) {
    switch (to.name) {
      case "Home":
        return {
          name: "User profile",
          query: { ...to.query },
          params: { ...to.params }
        };
    }
  } else if (
    [
      "administrator",
      "studycoordinator",
      "datamanager",
      "dataadministrator",
      "developer",
      "cra",
      "deo",
      "cst"
    ].includes(role())
  ) {
    switch (to.name) {
      case "Home":
        return {
          name: "Search patient",
          query: { ...to.query },
          params: { ...to.params }
        };
    }
  } else {
    switch (to.name) {
      case "Home":
        return {
          name: "Dashboard",
          query: { ...to.query },
          params: { ...to.params }
        };
    }
  }
  return undefined;
}
