import { Patient, DomainCollection, hasFixedLabels, InterviewItem, Page, getItem, getItemWording, getItemType, isML, isMLstring, getTranslation, InclusionsBySites, QueriesBySites, KPISet, hasPivot, DomainCollectionImpl, Study, PageSet, PageItem, Interview, Process, Site, Workflow, StudyBuilder, PageBuilder, PageSetBuilder, WorkflowBuilder, PageItemBuilder, PatientBuilder, InterviewBuilder, InterviewItemBuilder, ProcessBuilder, getVariableName, isComputed, Rules, User } from 'spiral';
import deepEqual from 'fast-deep-equal';
import debug from 'debug';
import { Stealer } from 'stealer';

class PatientGetOptions {
    offset = 0;
    limit = Infinity;
    deleted = false;
}

class InterviewSaveOptions {
    strict = true;
}

function pick(obj) {
    const result = {};
    for (const k in obj) {
        pickone(obj, k, result);
    }
    return result;
}
function pickone(obj, k, result) {
    const v = obj[k];
    if (v !== undefined) {
        if (v instanceof Date)
            result[k] = v.toISOString();
        else
            result[k] = v;
    }
}

class PatientSummary {
    study;
    patientCode;
    siteCode;
    currentInterview;
    interviewCount;
    pins;
    kpis;
    alerts;
    included;
    inclusionDate;
    constructor(study, x, workflow) {
        this.study = study;
        Object.defineProperty(this, "study", { enumerable: false });
        if (x instanceof Patient) {
            this.patientCode = x.patientCode;
            this.siteCode = x.site.siteCode;
            const interview = x.currentInterview(workflow);
            this.currentInterview = this.interviewSummary(x, interview);
            this.interviewCount = x.interviews.length;
            this.pins = this.itemMap(x.currentItems(study.pins).filter((i) => !!i));
            this.kpis = this.kpiMap(x.interviews.flatMap(i => i.kpis), study.options);
            this.alerts = x
                .alerts(workflow ?? study.mainWorkflow)
                .filter(a => a.item ? a.interview.pageSet.getPagesForItem(a.item).length > 0 : true)
                .map(a => ({
                message: a.message,
                interview: this.interviewSummary(x, a.interview),
                page: this.pageSummary(a.interview, a.item ?? a.page),
                item: a.item ? this.itemSummary(a.item) : undefined,
                type: a.type,
                tags: a.tags,
            }));
            this.included = x.included;
            this.inclusionDate = x.inclusionDate;
        }
        else {
            this.patientCode = x.patientCode;
            this.siteCode = x.siteCode;
            this.currentInterview = this.currentInterview;
            this.interviewCount = x.interviewCount;
            this.pins = x.pins;
            this.kpis = x.kpis;
            this.alerts = DomainCollection(...x.alerts);
            this.inclusionDate = new Date(x.inclusionDate);
            this.included = x.included;
        }
        Object.freeze(this);
    }
    interviewSummary(patient, interview) {
        if (!interview)
            return {};
        return {
            type: interview.pageSet.type,
            status: interview.status,
            date: interview.date,
            index: patient.interviews.indexOf(interview) + 1,
            fillRate: interview.fillRate,
        };
    }
    itemMap(items) {
        return items.reduce((r, i) => {
            const { variableName, ...item } = this.itemSummary(i);
            return {
                ...r,
                [variableName]: item,
            };
        }, {});
    }
    kpiMap(kpis, options) {
        return kpis.reduce((r, i) => this.isPivotKpi(i) ? this.pivotKpi(r, options, i) : this.kpi(r, i), {});
    }
    isPivotKpi(i) {
        return Array.isArray(i) && hasFixedLabels(i[1].type);
    }
    kpi(r, i) {
        i = i instanceof InterviewItem ? i : i[0];
        const { variableName, ...item } = this.itemSummary(i);
        return {
            ...r,
            [variableName]: item,
        };
    }
    pivotKpi(r, options, i) {
        const [kpi, pivot] = i;
        const { variableName, ...item } = this.itemSummary(kpi);
        const pivotVariable = pivot.pageItem.variableName;
        const pivotValue = pivot.value;
        return {
            ...r,
            [condStatVariable(variableName, pivotVariable, pivotValue)]: {
                ...item,
                kpi: condStatTitle(kpi.pageItem.kpi, options, pivotValue),
            },
        };
    }
    pageSummary(interview, x) {
        const page = x instanceof Page ? x : interview.pageSet.getPagesForItem(x)[0];
        const px = interview.pageSet.pages.indexOf(page);
        return {
            name: page.name,
            index: px + 1,
        };
    }
    itemSummary(item) {
        const pageItem = getItem(item);
        return {
            wording: getItemWording(item),
            variableName: pageItem.variableName,
            type: pick(getItemType(item)),
            value: item instanceof InterviewItem ? item.value : undefined,
            specialValue: item instanceof InterviewItem ? item.specialValue : undefined,
            pin: pageItem.pin,
            kpi: pageItem.kpi,
        };
    }
}
class SummaryGenericDriver {
    patientDriver;
    siteDriver;
    constructor(patientDriver, siteDriver) {
        this.patientDriver = patientDriver;
        this.siteDriver = siteDriver;
    }
    async getPatientSummaries(study, site, x, options) {
        const select = Array.isArray(x) ? x : [];
        options = Array.isArray(x) ? options : x;
        const config = { ...new PatientGetOptions(), limit: 20, ...options };
        const patients = await this.getPatients(study, site, config);
        const summaries = patients.map(p => new PatientSummary(study, p, study.mainWorkflow));
        return select.length > 0
            ? summaries.map(p => select.reduce((s, q) => ({ ...s, [q]: p[q] }), {}))
            : summaries;
    }
    async getPatients(study, site, options) {
        return site
            ? await this.patientDriver.getBySite(study, site, options)
            : await this.patientDriver.getAll(study, await this.siteDriver.getAll(study), options);
    }
}
function condStatVariable(variableName, pivotVarName, pivotValue) {
    return `${variableName}|${pivotVarName}=${pivotValue}`;
}
function condStatTitle(kpi, options, condValue) {
    const title = isML(kpi.title)
        ? kpi.title
        : { [options.defaultLang ?? "en"]: kpi.title };
    return Object.entries(title).reduce((r, [lang, title]) => {
        const label = isMLstring(condValue) && isML(condValue)
            ? getTranslation(condValue, lang)
            : kpi.pivot.type.label(condValue, lang);
        return {
            ...r,
            [lang]: buildTitle(title, kpi, label ?? String(condValue)),
        };
    }, {});
}
function buildTitle(title, kpi, label) {
    if (title.includes("${value}"))
        return title.replace("${value}", String(label));
    return `${title}|${kpi.pivot.variableName}=${label}`;
}

class AuditRecord {
    target;
    siteCode;
    date;
    operation;
    changes;
    user;
    constructor(target, siteCode, date, operation, changes, user) {
        this.target = target;
        this.siteCode = siteCode;
        this.date = date;
        this.operation = operation;
        this.changes = changes;
        this.user = user;
    }
    static operationOrder = [
        "create",
        "update",
        "input",
        "signature",
        "query",
        "checking",
    ];
    getOperationIndex() {
        return AuditRecord.operationOrder.indexOf((this.changes.action ?? getTranslation(this.operation, "en")));
    }
    isAfter(other) {
        const thisTime = Math.trunc(this.date.getTime() / 1000);
        const otherTime = Math.trunc(other.date.getTime() / 1000);
        return thisTime != otherTime
            ? thisTime > otherTime
            : this.getOperationIndex() > other.getOperationIndex();
    }
}
class AuditTrail extends Array {
    item;
    constructor(records, pageItem, lang) {
        super();
        this.item = new InterviewItem(pageItem, undefined);
        records
            .sort((a, b) => this.compare(a, b))
            .forEach(rec => {
            if (this.isProcess(rec))
                this.pushProcess(rec, lang);
            else
                this.pushValue(rec, lang);
        });
        Object.defineProperty(this, "item", { enumerable: false });
        Object.defineProperty(this, "checking", { enumerable: false });
    }
    compare(a, b) {
        return a.isAfter(b) ? 1 : -1;
    }
    isProcess(rec) {
        return "action" in rec.changes;
    }
    pushValue(rec, lang) {
        const current = this.applyChanges(rec);
        if (this.isCreation(rec))
            rec = Object.assign(Object.create(AuditRecord.prototype), {
                ...rec,
                operation: "create",
            });
        if (this.isRelevant(rec, current))
            this.pushRecord(rec, lang, current);
        this.item = current;
    }
    applyChanges(rec) {
        const changes = {
            ...rec.changes,
            ...this.undefValue(rec),
            ...this.undefSpecial(rec),
        };
        return this.item.update(changes);
    }
    undefValue(rec) {
        return rec.changes.specialValue != undefined ||
            Object.keys(rec.changes).length == 0
            ? { value: undefined, unit: undefined }
            : "specialValue" in rec.changes
                ? { specialValue: undefined }
                : {};
    }
    undefSpecial(rec) {
        return rec.changes.value != undefined ||
            Object.keys(rec.changes).length == 0
            ? { specialValue: undefined }
            : "value" in rec.changes
                ? { value: undefined }
                : {};
    }
    isRelevant(rec, current) {
        return rec.operation != "create" || typeof current.label() != "undefined";
    }
    isCreation(rec) {
        return this.length == 0 && rec.operation == "update";
    }
    pushProcess(rec, lang) {
        if (rec.operation != "create" && rec.operation != "update")
            this.pushRecord(rec, lang, this.item);
    }
    pushRecord(rec, lang, current) {
        super.push({
            target: rec.target,
            siteCode: rec.siteCode,
            date: rec.date,
            operation: rec.operation,
            previous: this.item.label(lang),
            current: current.label(lang),
            user: rec.user,
        });
    }
}

class KpiGenericDriver {
    siteDriver;
    summaryDriver;
    constructor(siteDriver, summaryDriver) {
        this.siteDriver = siteDriver;
        this.summaryDriver = summaryDriver;
    }
    async getAll(study, sites) {
        const summaries = await this.getAllSummaries(study, sites);
        if (typeof sites == "undefined")
            sites = await this.siteDriver.getAll(study);
        const inclusionsBySites = new InclusionsBySites(study, DomainCollection(...sites), DomainCollection(...summaries));
        const queriesBySites = new QueriesBySites(study, DomainCollection(...sites), DomainCollection(...summaries));
        const kpiSet = new KPISet(study, summaries, { site: true });
        const itemStats = this.getKpis(study).map(k => kpiSet.variableNames.includes(k.variableName)
            ? kpiSet.getMatrix("@SITE", k.variableName)
            : getKPIForUnknownConditional(study, kpiSet, "@SITE", k.variableName));
        return [[inclusionsBySites, queriesBySites, ...itemStats], kpiSet];
    }
    getKpis(study) {
        const allKpis = study.kpis.flatMap(item => this.isPivotKpi(item)
            ? this.getPivotKpis(study, item)
            : [item]);
        return [...allKpis].sort((a, b) => this.compateKpi(a, b));
    }
    compateKpi(a, b) {
        const [variableName1, condValue1] = a.variableName.split("|");
        const [variableName2, condValue2] = b.variableName.split("|");
        if (!condValue1 && !condValue2)
            return variableName1.localeCompare(variableName2);
        if (condValue1 && condValue2)
            return condValue1.localeCompare(condValue2);
        if (condValue1)
            return condValue1.localeCompare(variableName2);
        if (condValue2)
            return variableName1.localeCompare(condValue2);
        return 0;
    }
    isPivotKpi(item) {
        return hasPivot(item.kpi) && hasFixedLabels(item.kpi.pivot.type);
    }
    getPivotKpis(study, item) {
        const pivotKpi = item.kpi;
        const pivotType = pivotKpi.pivot.type;
        return pivotType.labels.map(l => {
            const pivotValue = getTranslation(l, "__code__", study.options.defaultLang);
            return {
                variableName: condStatVariable(item.variableName, pivotKpi.pivot.variableName, pivotValue),
                type: item.type,
                kpi: condStatTitle(pivotKpi, study.options, l),
            };
        });
    }
    getAllSummaries(study, sites) {
        if (typeof sites == "undefined")
            return this.getSummaries(study);
        return Promise.all(sites.map(s => this.getSummaries(study, s))).then(summaries => Array.prototype.concat([], ...summaries));
    }
    getSummaries(study, site) {
        return this.summaryDriver.getPatientSummaries(study, site, [
            "patientCode",
            "siteCode",
            "currentInterview",
            "interviewCount",
            "pins",
            "alerts",
            "included",
            "kpis",
            "inclusionDate",
        ]);
    }
}
function getKPIForUnknownConditional(study, kpiSet, rowVariable, colVariable) {
    const [mainVariable, condition] = colVariable.split("|");
    const [conditionalVariable, conditionalValue] = condition.split("=");
    if (mainVariable == colVariable)
        throw `unknown variable ${colVariable}`;
    const v = kpiSet.variableNames.find(v => v.startsWith(`${mainVariable}|${conditionalVariable}`));
    const m = kpiSet.getMatrix(rowVariable, v);
    return Object.assign(Object.create(Object.getPrototypeOf(m)), {
        columnSums: [m.columnSums[0].map(() => NaN)],
        rowSums: m.rowSums.map(() => [NaN]),
        datasource: {
            ...m.datasource,
            column: {
                ...m.datasource.column,
                variableName: colVariable,
            },
        },
        title: condStatTitle(study.getItemForVariable(mainVariable)?.kpi, study.options, conditionalValue),
        values: m.values.map(r => r.map(() => NaN)),
    });
}

class Document {
    name;
    title;
    tags;
    __keys__ = {};
    hash = 0;
    content = new Uint8Array();
    visibility = "study";
    constructor(name, title, tags, kwargs) {
        this.name = name;
        this.title = title;
        this.tags = tags;
        Object.assign(this, kwargs);
        Object.freeze(this);
    }
    update(kwargs) {
        const { __keys__, ...others } = kwargs;
        if (Object.keys(others).length == 0) {
            Object.assign(this.__keys__, __keys__);
            return this;
        }
        return new Document(this.name, this.title, this.tags, {
            ...this,
            ...kwargs,
        });
    }
}
function getAllTags(documents) {
    return documents
        .reduce((res, d) => {
        if (d?.tags)
            res = res.append(...d.tags);
        return res;
    }, DomainCollection())
        .filter((value, index, it) => it.indexOf(value) === index);
}

function isReponse(r) {
    return (typeof r.response != "undefined" &&
        "json" in r.response &&
        typeof r.response.json == "function");
}
class SpiralError extends Error {
    constructor(err) {
        const constraintErrors = ["SQLITE_CONSTRAINT", "23505"];
        if (constraintErrors.includes(err.code ?? ""))
            super("Failed to register - MUST BE UNIQUE");
        else
            super(err.message);
        this.name = "SpiralError";
    }
}
function errorMessage(message, statusCode) {
    return {
        message: message,
        statusCode: statusCode ?? 400,
    };
}
class SpiralClientError extends Error {
    errors;
    constructor(errors) {
        super("Spiral Error");
        this.errors = errors;
    }
}
async function handleClientError(error) {
    if (isReponse(error)) {
        const json = await error.response.json();
        return Promise.reject(json.errors);
    }
    return Promise.reject(JSON.parse(error.response.body).errors);
}

function hasChanges(obj) {
    return (typeof obj.__changes__ == "object" &&
        Object.keys(obj.__changes__).length > 0);
}
function resetChanges(current, changes = {}) {
    for (const x in current.__changes__)
        delete current.__changes__[x];
    Object.assign(current.__changes__, changes);
}
function getChanges(target, updated, kwargs) {
    const __changes__ = {};
    let key;
    for (key in kwargs)
        if (shouldTrack(target, updated, key))
            setChangedValue(__changes__, updated, key);
    return __changes__;
}
function shouldTrack(target, kwargs, key) {
    const targetValue = target[key];
    const sourceValue = kwargs[key];
    return isTrackable(targetValue) && !deepEqual(targetValue, sourceValue);
}
function isTrackable(targetValue) {
    return !(targetValue instanceof DomainCollectionImpl);
}
function setChangedValue(__changes__, kwargs, key) {
    __changes__[key] = kwargs[key];
}
function mergeChanges(target, changes) {
    if (!hasChanges({ __changes__: changes }))
        return target.__changes__ ?? {};
    const __changes__ = { ...target.__changes__ };
    for (const k in changes)
        mergeChange(__changes__, target, changes, k);
    return __changes__;
}
function isChangedInTarget(target, k) {
    return hasChanges(target) && k in target.__changes__;
}
const undef = { undef: true };
function isUndef(obj) {
    const o = typeof obj == "object" ? obj : undefined;
    return o?.undef;
}
function isOriginalValue(target, changes, k) {
    const original = target.__changes__[k];
    if (changes[k] == undefined)
        return isUndef(original);
    return deepEqual(changes[k], original);
}
function mergeChange(__changes__, target, changes, k) {
    if (isChangedInTarget(target, k)) {
        if (isOriginalValue(target, changes, k))
            delete __changes__[k];
    }
    else {
        const original = target[k];
        __changes__[k] = (original ?? undef);
    }
}

function hasKeys(obj) {
    return (typeof obj.__keys__ == "object" && Object.keys(obj.__keys__).length > 0);
}
function setKeys(obj, keys) {
    Object.assign(obj.__keys__, keys);
}

function isZip(o) {
    return Array.isArray(o);
}
function isZippable(o) {
    return "zip" in o;
}
function weave$1(proto) {
    proto.updateNoTrack =
        proto.update ??
            function (kwargs) {
                return Object.assign(this, kwargs);
            };
    proto.update = function (kwargs) {
        if (isZip(kwargs) && isZippable(this))
            return this.zip(kwargs);
        if (isZip(kwargs))
            throw "zipped type applied to non zippable";
        const { __untrack__, __keys__, __changes__, ...others } = kwargs;
        if (__untrack__) {
            const updated = this.updateNoTrack?.(others);
            if (__keys__)
                setKeys(updated, __keys__);
            resetChanges(updated);
            return updated;
        }
        const updated = this.updateNoTrack(kwargs);
        if (this != updated && !hasChanges({ __changes__ })) {
            const __changes__ = getChanges(this, updated, others);
            if (hasChanges({ __changes__ })) {
                const merged = mergeChanges(this, __changes__);
                resetChanges(updated, merged);
            }
        }
        return updated;
    };
    proto.isStale = function (omit = []) {
        if (isNew(this) || isModified(this, omit))
            return freshen;
        return false;
    };
}
function isNew(obj) {
    return !hasKeys(obj);
}
function isModified(obj, omit) {
    return (hasChanges(obj) &&
        !Object.keys(obj.__changes__)?.every(c => omit.includes(c)));
}
function freshen(p) {
    return { __keys__: { __flag__: 1 }, __untrack__: true, ...p };
}
weave$1(Study.prototype);
weave$1(Page.prototype);
weave$1(PageSet.prototype);
weave$1(PageItem.prototype);
weave$1(Patient.prototype);
weave$1(Interview.prototype);
weave$1(InterviewItem.prototype);
weave$1(Process.prototype);
weave$1(Site.prototype);
weave$1(Workflow.prototype);

function weave(proto) {
    Object.assign(proto, { __keys__: {}, __changes__: {} });
    proto.track = function (keys, changes) {
        Object.assign(this, keys, changes);
    };
    proto.buildNoTrack = proto.build;
    proto.build = function (...args) {
        const instance = this.buildNoTrack(...args);
        if (hasKeys(this) && this.__keys__ != instance.__keys__)
            setKeys(instance, this.__keys__);
        if (hasChanges(this) && instance.__changes__ != this.__changes__)
            resetChanges(instance, this.__changes__);
        return instance;
    };
}
weave(StudyBuilder.prototype);
weave(PageBuilder.prototype);
weave(PageSetBuilder.prototype);
weave(WorkflowBuilder.prototype);
weave(PageItemBuilder.prototype);
weave(PatientBuilder.prototype);
weave(InterviewBuilder.prototype);
weave(InterviewItemBuilder.prototype);
weave(ProcessBuilder.prototype);

function ruleSerialize(rule) {
    return pick(rule);
}
function ruleDeserialize(ib, rule) {
    const args = rule;
    ib.rule(args);
}

function trakDeserialize(b, t) {
    b.track({ __keys__: t.__keys__ }, { __changes__: t.__changes__ });
}
function trakDeserializeArray(b, t) {
    for (const i in b) {
        trakDeserialize(b[i], t[i]);
    }
}

function itemDeserialize(pb, item) {
    const qb = pb.question(item.wording, item.variableName, { ...item.type });
    if (item.units) {
        const ub = Array.isArray(item.units)
            ? qb.unit(...item.units)
            : qb.unit(...item.units.values);
        if (!Array.isArray(item.units) && item.units.isExtendable)
            ub.extendable();
    }
    if (typeof item.default != "undefined")
        qb.defaultValue(item.default);
    item.rules.forEach(rule => {
        ruleDeserialize(qb, rule);
    });
    if (item.itemComment)
        qb.comment(item.itemComment);
    if (item.pinTitle)
        qb.pin(item.pinTitle);
    if (item.kpiTitle)
        qb.kpi(item.kpiTitle, item.kpiPivot);
    trakDeserialize(qb, item);
}
function itemSerialize(item, track = true) {
    const { instance, rules, type, comment, section, pin, kpi, defaultValue, __keys__, __changes__, ...node } = getItem(item);
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        type: pick(type),
        rules: [...rules.map(ruleSerialize)],
        ...trackInfos,
    }, section ? { section } : {}, comment ? { itemComment: comment } : {}, pin ? { pinTitle: pin } : {}, kpi ? { kpiTitle: hasPivot(kpi) ? kpi.title : kpi } : {}, kpi && hasPivot(kpi) ? { kpiPivot: kpi.pivot.variableName } : {}, typeof defaultValue != "undefined" ? { default: defaultValue.value } : {});
}

const noSection = JSON.stringify(undefined);
function pageDeserialize(b, page) {
    const pb = b.page(page.name);
    if (page.exportConfig)
        pb.exportTo(page.exportConfig);
    let section = noSection;
    page.includes.forEach(q => {
        section = libraryDeserialize(pb, q, section);
    });
    section = endSection(pb, section);
    trakDeserialize(pb, page);
}
function libraryDeserialize(pb, q, section) {
    if ("wording" in q) {
        section = startSection(pb, q, section);
        itemDeserialize(pb, q);
    }
    else {
        section = endSection(pb, section);
        const pi = pb.include(q.pageName);
        if (q.variableNames)
            pi.select(...q.variableNames);
        if (q.contexts)
            q.contexts.forEach(c => pi.context(...c));
    }
    return section;
}
function startSection(pb, q, section) {
    const s = JSON.stringify(q.section);
    if (section != s) {
        endSection(pb, section);
        if (s != noSection)
            pb.startSection(q.section);
    }
    return s;
}
function endSection(pb, section) {
    if (section != noSection)
        pb.endSection();
    return noSection;
}
function pageSerialize(page, options, track = true) {
    const { includes, items, exportConfig, __keys__, __changes__, ...node } = page;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        includes: [...includes.map(i => librarySerialize(i, options, track))],
        ...(exportConfig ? { exportConfig } : {}),
        ...trackInfos,
    });
}
function librarySerialize(i, options, track = true) {
    if (i instanceof PageItem)
        return itemSerialize(i, track);
    else {
        const { page, pageItems, contexts } = i;
        return Object.assign({
            pageName: getTranslation(page.name, "__code__", options.defaultLang),
        }, pageItems
            ? { variableNames: [...pageItems.map(i => i.variableName)] }
            : {}, contexts ? { contexts: [...contexts.map(contextSerialize)] } : {});
    }
}
function contextSerialize(c) {
    return [c.pageItem.variableName, c.context];
}

function pageSetDeserialize(b, pageSet) {
    const vb = b.pageSet(pageSet.type);
    if (pageSet.datevar)
        vb.datevariable(pageSet.datevar);
    const pageNames = pageSet.pageNames.map(p => pageSet.mandatoryPageNames?.includes(p) ? { name: p, mandatory: true } : p);
    if (pageNames.length > 0)
        vb.pages(...pageNames);
    trakDeserialize(vb, pageSet);
}
function pageSetSerialize(pageSet, options, track = true) {
    const { pages, mandatoryPages, datevar, __keys__, __changes__, ...node } = pageSet;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        pageNames: [
            ...pages.map(p => getTranslation(p.name, "__code__", options.defaultLang)),
        ],
        ...trackInfos,
    }, typeof mandatoryPages != "undefined"
        ? {
            mandatoryPageNames: [
                ...mandatoryPages.map(p => getTranslation(p.name, "__code__", options.defaultLang)),
            ],
        }
        : {}, datevar ? { datevar } : {});
}

function workflowSerialize(workflow, options, track = true) {
    const { info, single, many, sequence, stop, main, processes, processProperties, signedPageSets, notifications, __keys__, __changes__, ...node } = workflow;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        ...(info ? { infoType: getPageSetName(info, options) } : {}),
        singleTypes: getPageSetNames(single, options),
        manyTypes: getPageSetNames(many, options),
        sequenceTypes: getPageSetNames(sequence, options),
        stopTypes: getPageSetNames(stop, options),
        actions: [...processes.map(p => [...p])],
        processProperties: processProperties,
        signedTypes: getPageSetNames(signedPageSets, options),
        notifications: [...notifications],
        ...trackInfos,
    });
}
function workflowDeserialize(b, workflow) {
    const wb = b.workflow({ name: workflow.name, raw: true });
    if (workflow.infoType)
        wb.home(workflow.infoType);
    wb.n(...workflow.manyTypes);
    wb.seq(...workflow.sequenceTypes);
    wb.end(...workflow.stopTypes);
    wb.one(...workflow.singleTypes);
    if (workflow.actions.length > 0)
        workflow.actions.forEach(p => wb.process(...p));
    wb.signOn(...workflow.signedTypes);
    if (workflow.processProperties)
        Object.entries(workflow.processProperties).forEach(([a, p]) => wb.processProps(a, ...p));
    wb.notify(...workflow.notifications);
    trakDeserialize(wb, workflow);
}
function getPageSetNames(pageSets, options) {
    return [...pageSets.map(p => getPageSetName(p, options))];
}
function getPageSetName(p, options) {
    return getTranslation(p.type, "__code__", options.defaultLang);
}

function crossRuleSerialize(crossItemRule) {
    const { pageItems, name, when, args: { rule, ...args }, } = crossItemRule;
    return Object.assign({
        variableNames: [...pageItems.map(i => getVariableName(i))],
        args: ruleSerialize({ name, ...args }),
        when,
    });
}
function crossRuleDeserialize(b, crossRule) {
    const { variableNames, args } = crossRule;
    b.rule(variableNames, args).trigger(crossRule.when);
}

function studyDeserialize(b, study) {
    b.options(study.config);
    b.study(study.name);
    study.pageSets.forEach(v => pageSetDeserialize(b, v));
    study.pages.forEach(p => pageDeserialize(b, p));
    study.crossRules.forEach(r => crossRuleDeserialize(b, r));
    study.workflows.forEach(w => workflowDeserialize(b, w));
    trakDeserialize(b, study);
}
function studySerialize(study, track = true) {
    const { options, workflows, pageSets, pages, crossRules, __keys__, __changes__, ...node } = study;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        config: options,
        workflows: [
            ...workflows.map(w => workflowSerialize(w, study.options, track)),
        ],
        pageSets: [...pageSets.map(v => pageSetSerialize(v, study.options, track))],
        pages: [...pages.map(p => pageSerialize(p, study.options, track))],
        crossRules: [...crossRules.map(crossRuleSerialize)],
        ...trackInfos,
    });
}
function clone(study) {
    const studyBuilder = new StudyBuilder();
    const studyNode = studySerialize(study, false);
    studyDeserialize(studyBuilder, studyNode);
    return studyBuilder;
}

function interviewItemSerialize(interviewItem, track = true) {
    const { pageItem, value, unit, specialValue, context, messages, __keys__, __changes__, ...node } = interviewItem;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        variableName: pageItem.variableName,
        instance: pageItem.instance,
        ...trackInfos,
    }, typeof value != "undefined"
        ? value instanceof Date
            ? { value: isNaN(value.getTime()) ? undefined : value.toISOString() }
            : { value }
        : {}, unit ? { unit } : {}, specialValue ? { specialValue } : {}, context ? { context } : {}, messages ? { messages } : {});
}
function interviewItemDeserialize(ib, interviewItem) {
    const b = ib
        .item(interviewItem.variableName, interviewItem.instance)
        .context(interviewItem.context);
    if (typeof interviewItem.value != "undefined")
        b.value(interviewItem.value);
    if (typeof interviewItem.unit != "undefined")
        b.unit(interviewItem.unit);
    if (typeof interviewItem.specialValue != "undefined")
        b.specialValue(interviewItem.specialValue);
    if (typeof interviewItem.messages != "undefined")
        b.messages(interviewItem.messages);
    trakDeserialize(b, interviewItem);
}

function processSerialize(process, options, track = true) {
    const { page, pageItem, isProtected, __keys__, __changes__, ...p } = process;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return {
        ...pick(p),
        ...(page
            ? { pageName: getTranslation(page.name, "__code__", options.defaultLang) }
            : {}),
        ...(pageItem
            ? { variableName: pageItem.variableName, instance: pageItem.instance }
            : {}),
        ...trackInfos,
    };
}
function processDeserialize(ib, process) {
    const { action, date, pageName, variableName, instance, ...args } = process;
    const b = ib
        .process(action)
        .init(date ? new Date(date) : new Date())
        .args({ ...args });
    if (pageName)
        b.page(pageName);
    if (variableName)
        b.pageItem(variableName, instance);
    trakDeserialize(b, process);
}

function interviewSerialize(interview, track = true) {
    const { pageSet, options, items, processes, lastInput, __keys__, __changes__, ...node } = interview;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        pageSetType: getTranslation(pageSet.type, "__code__", interview.options.defaultLang),
        items: [...items.map(i => interviewItemSerialize(i, track))],
        processes: [
            ...processes.map(p => processSerialize(p, interview.options, track)),
        ],
        lastInput: lastInput.toISOString(),
        ...trackInfos,
    });
}
function interviewDeserialize(pb, interview) {
    const ib = pb.interview(interview.pageSetType);
    ib.init(interview.nonce, new Date(interview.lastInput));
    ib.items(interview.items);
    if (interview.processes && interview.processes.length > 0)
        for (const process of interview.processes) {
            processDeserialize(ib, process);
        }
    trakDeserializeArray(ib.interviewItems, interview.items);
    trakDeserialize(ib, interview);
}

function patientSerialize(patient, track = true) {
    const { interviews, __keys__, __changes__, ...node } = patient;
    const trackInfos = track ? { __keys__, __changes__ } : {};
    return Object.assign(node, {
        interviews: [...interviews.map(p => interviewSerialize(p, track))],
        ...trackInfos,
    });
}
function patientDeserialize(pb, patient) {
    pb.init(patient.patientCode, patient.site.siteCode);
    patient.interviews.forEach(i => {
        interviewDeserialize(pb, i);
    });
    trakDeserialize(pb, patient);
}

class StudyWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    getByName(name) {
        const b = new StudyBuilder();
        const query = "query ($name:String!){study(name:$name)}";
        const variables = JSON.stringify({ name });
        return this.client
            .get("graphql", { searchParams: { query, variables } })
            .json()
            .then(response => {
            studyDeserialize(b, response.data.study);
            return b.build();
        })
            .catch(async (error) => await handleClientError(error));
    }
    save(study) {
        const b = new StudyBuilder();
        const query = {
            query: "mutation ($name: String!, $study: Json!){ saveStudy(name: $name, study: $study) }",
            variables: { name: study.name, study: studySerialize(study) },
        };
        return this.client
            .post("graphql", { json: query })
            .json()
            .then(response => {
            studyDeserialize(b, response.data.saveStudy);
            return b.build();
        })
            .catch(async (error) => await handleClientError(error));
    }
}

class PatientWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    getAll(study, sites, options = { limit: Infinity }) {
        if (options.limit > 20)
            throw "getting more than 20 patients is not allowed by client, use summry driver instead";
        const query = "query ($study:String!, $offset: Int, $limit:Int){patients(study:$study, offset:$offset, limit:$limit)}";
        const variables = JSON.stringify({
            study: study.name,
            ...new PatientGetOptions(),
            ...options,
        });
        return this.client
            .get("graphql", { searchParams: { query, variables } })
            .json()
            .then(response => response.data.patients.map(p => {
            const b = new PatientBuilder(study, DomainCollection(...sites));
            patientDeserialize(b, p);
            return b.build();
        }))
            .catch(async (error) => await handleClientError(error));
    }
    getByPatientCode(study, sites, patientCode) {
        const b = new PatientBuilder(study, DomainCollection(...sites));
        const query = "query ($study:String!, $patient:String!){patient(study:$study, code:$patient)}";
        const variables = JSON.stringify({
            study: study.name,
            patient: patientCode,
        });
        return this.client
            .get("graphql", { searchParams: { query, variables } })
            .json()
            .then(response => {
            patientDeserialize(b, response.data.patient);
            return b.build();
        })
            .catch(async (error) => await handleClientError(error));
    }
    getBySite(study, site, options = { limit: Infinity }) {
        if (options.limit > 20)
            throw "getting more than 20 patients is not allowed by client, use summry driver instead";
        const query = "query ($study:String!, $site:String!, $offset:Int!, $limit:Int!){patients(study:$study, site:$site, offset:$offset, limit:$limit)}";
        const variables = JSON.stringify({
            study: study.name,
            site: site.siteCode,
            ...new PatientGetOptions(),
            ...options,
        });
        return this.client
            .get("graphql", { searchParams: { query, variables } })
            .json()
            .then(response => response.data.patients.map(p => {
            const b = new PatientBuilder(study, DomainCollection(site));
            patientDeserialize(b, p);
            return b.build();
        }))
            .catch(async (error) => await handleClientError(error));
    }
    save(study, patient) {
        const { patientCode, site, interviews, ...kwargs } = patient;
        const query = {
            query: patient.patientCode.length > 0
                ? "mutation ($study:String!, $code:String!, $site:String!, $kwargs:Json){savePatient(study:$study, code:$code, site:$site, kwargs:$kwargs)}"
                : "mutation ($study:String!, $site:String!, $kwargs:Json){createPatient(study:$study, site:$site, kwargs:$kwargs)}",
            variables: {
                study: study.name,
                ...(patientCode.length > 0 ? { code: patientCode } : {}),
                site: site.siteCode,
                kwargs,
            },
        };
        return this.client
            .post("graphql", { json: query })
            .json()
            .then(response => patient.patientCode.length > 0
            ? response.data.savePatient
            : response.data.createPatient)
            .catch(async (error) => await handleClientError(error));
    }
    delete(study, patient) {
        const query = {
            query: "mutation ($study: String!, $code: String!, $reason: Json!){ deletePatient(study: $study, code: $code, reason: $reason) }",
            variables: {
                study: study.name,
                code: patient.patientCode,
                reason: patient.__delete__,
            },
        };
        return this.client
            .post("graphql", { json: query })
            .json()
            .then(() => { })
            .catch(async (error) => await handleClientError(error));
    }
}

const dlog = debug("spiral:drivers");
function LoggingProxy(target) {
    if (isNode && !dlog.enabled)
        return target;
    const name = Object.getPrototypeOf(target).constructor.name;
    return new Proxy(target, {
        get: (t, p, r) => {
            const q = Reflect.get(t, p, r);
            if (!p.toString().startsWith("get") && !p.toString().startsWith("save"))
                return q;
            return async (...args) => {
                const namespace = `${name}.${String(p)}`;
                dlog(namespace, "start");
                const result = await q.call(t, ...args);
                dlog(namespace, "end");
                return result;
            };
        },
    });
}
const isNode = typeof process?.hrtime == "function";

function isFtor(o) {
    return !o.prototype || !o.prototype.constructor.name;
}
function asFtor(o) {
    if (isFtor(o))
        return o;
    return (...a) => new o(...a);
}
class Builder {
    ftor;
    args;
    constructor(ftor, args) {
        this.ftor = ftor;
        this.args = args;
    }
    with(ftor, ...args) {
        return new Builder((...a) => asFtor(ftor)(this.ftor(...this.args), ...a), args);
    }
    withLogging(on = true) {
        if (on)
            return new Builder((...a) => LoggingProxy(this.ftor(...a)), this.args);
        return this;
    }
    __driver;
    get() {
        if (typeof this.__driver == "undefined")
            this.__driver = this.ftor(...this.args);
        return this.__driver;
    }
    static decorate(ftor, ...args) {
        return new Builder(asFtor(ftor), args);
    }
}

class SiteCacheDriver {
    driver;
    static cache;
    static _init() {
        SiteCacheDriver.cache = new Stealer({
            ttl: 600,
            unref: true,
        });
    }
    constructor(driver) {
        this.driver = driver;
    }
    async getAll(study) {
        const cachedSites = SiteCacheDriver.cache.get(study.name);
        if (typeof cachedSites != "undefined")
            return cachedSites;
        const sites = await this.driver.getAll(study);
        SiteCacheDriver.cache.set(study.name, sites);
        return sites;
    }
    async getBySiteCode(study, siteCode) {
        const sites = await this.getAll(study);
        return (sites.find(s => s.siteCode == siteCode) ??
            Promise.reject([errorMessage("unknown site")]));
    }
    async save(study, site) {
        const keys = await this.driver.save(study, site);
        SiteCacheDriver.cache.delete(study.name);
        return keys;
    }
}
SiteCacheDriver._init();

class PatientAuthorizationManager {
    study;
    patient;
    user;
    constructor(study, patient, user) {
        this.study = study;
        this.patient = patient;
        this.user = user;
    }
    get workflow() {
        return this.user
            ? this.study.workflow(this.user.role) ?? this.study.mainWorkflow
            : this.study.mainWorkflow;
    }
    canDelete() {
        return !this.canDeleteError();
    }
    canDeleteError() {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("administrator", "developer", "dataadministrator"))
            return "role is not authorized to delete";
        if (this.isFrozen())
            return "site is frozen";
        return "";
    }
    canCreate() {
        return !this.canCreateError();
    }
    canCreateError() {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("patient", "investigator", "developer", "dataadministrator", "cst", "deo"))
            return "role is not authorized to create items";
        if (this.isFrozen())
            return "site is frozen";
        return "";
    }
    canWriteItems(interview) {
        return !this.canWriteItemsError(interview);
    }
    canWriteItemsError(interview) {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("patient", "investigator", "developer", "dataadministrator", "cst", "deo"))
            return "role is not authorized to write items";
        if (this.isInWorkflow(interview))
            return "workflow is not authorized to write items for interview";
        if (this.isFrozen())
            return "site is frozen";
        return "";
    }
    canSign(interview) {
        return !this.canSignError(interview);
    }
    canSignError(interview) {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("developer", "dataadministrator", "investigator", "patient"))
            return "role is not authorized to sign";
        if (!this.hasAction(interview, "signature"))
            return "signature is not available for interview";
        if (this.isFrozen())
            return "site is frozen";
        return "";
    }
    canCheck(interview) {
        return !this.canCheckError(interview);
    }
    canCheckError(interview) {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("developer", "dataadministrator", "cra", "datamanager"))
            return "role is not authorized to check";
        if (!this.hasAction(interview, "checking"))
            return "checking is not available for interview";
        if (this.isFrozen())
            return "site is frozen";
        return "";
    }
    canOpenQuery(interview) {
        return !this.canOpenQueryError(interview);
    }
    canOpenQueryError(interview) {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("developer", "dataadministrator", "cra", "datamanager"))
            return "role is not authorized to open query";
        if (!this.hasAction(interview, "query"))
            return "querying is not available for interview";
        if (this.isFrozen())
            return "site is frozen";
        return "";
    }
    canCloseQuery(interview) {
        return !this.canCloseQueryError(interview);
    }
    canCloseQueryError(interview) {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("developer", "dataadministrator", "investigator"))
            return "role is not authorized to close query";
        if (!this.hasAction(interview, "query"))
            return "querying is not available for interview";
        if (this.isFrozen())
            return "site is frozen";
        return "";
    }
    isProtected(interview, item) {
        if (this.patient.signed)
            return true;
        if (!interview)
            return false;
        if (interview.currentProcess().isProtected)
            return true;
        if (!item)
            return false;
        return interview.isProtected(item);
    }
    isFrozen() {
        return this.patient.site.frozen;
    }
    isInRole(...roles) {
        return this.user ? roles.includes(this.user.role) : false;
    }
    isInWorkflow(interview) {
        return !this.workflow.pageSets.includes(interview.pageSet);
    }
    hasAction(interview, action) {
        return (interview.availableActions(this.workflow).includes(action) ||
            interview.availableProcesses(this.workflow).some(p => p.action == action));
    }
}
class StudyAuthorizationManager {
    study;
    user;
    constructor(study, user) {
        this.study = study;
        this.user = user;
    }
    canReadSite(siteCode) {
        return !this.canReadSiteError(siteCode);
    }
    canReadSiteError(siteCode) {
        if (!this.user)
            return "unknown user";
        if (this.user.sites &&
            this.user.sites.length > 0 &&
            !this.user.sites.some(s => s.siteCode == siteCode))
            return "not authorized to read patients from site";
        return "";
    }
    canReadPatient(patientCode) {
        return !this.canReadPatientError(patientCode);
    }
    canReadPatientError(patientCode) {
        if (!this.user)
            return "unknown user";
        if (this.user.patientIds &&
            this.user.patientIds.length > 0 &&
            !this.user.patientIds.includes(patientCode))
            return "not authorized to read patient";
        return "";
    }
    canSaveUser(user) {
        return !this.canSaveUserError(user);
    }
    canSaveUserError(user) {
        if (!this.user)
            return "unknown user";
        if (user?.role == "patient" &&
            !this.isInRole("developer", "administrator", "dataadministrator", "investigator"))
            return "role is not authorized to save user";
        if (user?.role != "patient" &&
            !this.isInRole("developer", "administrator", "dataadministrator"))
            return "role is not authorized to save user";
        return "";
    }
    canSaveStudy() {
        return !this.canSaveStudyError();
    }
    canSaveStudyError() {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("developer", "administrator", "dataadministrator"))
            return "role is not authorized to save study";
        return "";
    }
    canSaveSite() {
        return !this.canSaveSiteError();
    }
    canSaveSiteError() {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("developer", "administrator", "dataadministrator"))
            return "role is not authorized to save site";
        return "";
    }
    canSaveDocument(document) {
        return !this.canSaveDocumentError(document);
    }
    canSaveDocumentError(document) {
        if (!this.user)
            return "unknown user";
        if (!this.isInRole("developer", "administrator", "dataadministrator") &&
            document.visibility == "study")
            return "role is not authorized to save study document";
        if (!this.isInRole("developer", "investigator", "patient") &&
            document.visibility == "patient")
            return "role is not authorized to save patient document";
        return "";
    }
    isInRole(...roles) {
        return this.user ? roles.includes(this.user.role) : false;
    }
}

class FluentGenerator {
    code = [];
    current = [];
    argCount = 0;
    optionalArgs = [];
    get isEmpty() {
        return this.code.length == 0 && this.current.length == 0;
    }
    cancel() {
        if (this.current.length > 0)
            this.current = [];
        else if (this.code.length > 0)
            this.code.pop();
        this.argCount = 0;
    }
    compose(gen, prefix = 1, indent = typeof prefix == "string" ? 1 : prefix + 1) {
        this.initCall();
        this.code.push([() => gen.build(prefix, indent)]);
        return this;
    }
    call(name) {
        this.initCall();
        this.current.push(name, "(");
        return this;
    }
    prop(name) {
        this.initCall();
        this.current.push(name);
        return this;
    }
    arg(arg) {
        if (this.argCount > 0)
            this.current.push(", ");
        this.argCount++;
        this.pushArg(arg);
        return this;
    }
    pushArg(arg) {
        if (typeof arg == "string")
            this.current.push(`"${arg}"`);
        else if (typeof arg == "function")
            this.current.push(arg);
        else if (Array.isArray(arg)) {
            this.current.push("[");
            arg.forEach((a, i) => {
                if (i > 0)
                    this.current.push(", ");
                this.pushArg(a);
            });
            this.current.push("]");
        }
        else if (typeof arg == "object")
            this.current.push(JSON.stringify(arg));
        else
            this.current.push(String(arg));
    }
    args(...args) {
        for (const arg of args)
            this.arg(arg);
        return this;
    }
    opt(arg) {
        if (typeof arg == "undefined")
            this.optionalArgs.push(arg);
        else {
            this.args(...this.optionalArgs, arg);
            this.optionalArgs = [];
        }
        return this;
    }
    opts(...args) {
        for (const arg of args)
            this.opt(arg);
        return this;
    }
    build(prefix = "", indent = 1) {
        this.initCall();
        if (this.code.length == 0)
            return "";
        return `${typeof prefix == "string" ? prefix : "  ".repeat(prefix)}${this.code
            .map(c => this.buildInstruction(c, prefix))
            .filter(i => i != "")
            .join("\n" + "  ".repeat(indent))}`;
    }
    buildInstruction(instr, prefix) {
        return instr.map(tok => this.getToken(tok, prefix)).join("");
    }
    getToken(tok, prefix) {
        return typeof tok == "string" ? tok : tok(prefix);
    }
    initCall() {
        if (this.current.length > 0) {
            if (this.current.length == 1)
                this.code.push([".", this.current[0]]);
            else
                this.code.push([".", ...this.current, ")"]);
            this.current = [];
            this.optionalArgs = [];
            this.argCount = 0;
        }
    }
}

function getDynamicArgs(variableNames, args) {
    const formula = reverseFormula(args[0], variableNames, args);
    return extractFormula(formula);
}
function reverseFormula(formula, variableNames, a) {
    for (let i = a[1]; i > 0; i--) {
        formula = formula.replace(new RegExp(`\\$${i}`, "g"), variableNames[i - 1]);
    }
    return formula;
}
function extractFormula(formula) {
    const extract = /^\[(.*)\]$/.exec(formula);
    if (extract == null)
        return extractElement(formula);
    return [extractFormula(extract[1])];
}
function extractElement(content) {
    const elementExpr = /(^|,)(([^,()']+|(\[.*\])|((\(.*\))|('[^']*'))*)+)(?=(,|$))/g;
    let elementMatch;
    const result = [];
    while ((elementMatch = elementExpr.exec(content))) {
        const element = elementMatch[2];
        result.push(element);
    }
    return result;
}
function regenerateDynamic(pageItemGenerator, rule, values, ...extraArgs) {
    switch (rule) {
        case "inRange":
            pageItemGenerator?.inRange(asComputed(values[0]), asComputed(values[1]), extraArgs[0]);
            break;
        case "critical":
            pageItemGenerator?.critical(asComputed(values[0]), values[1], asComputed(values[2]));
            break;
        case "required":
        case "maxLength":
        case "fixedLength":
        case "decimalPrecision":
        case "letterCase":
            pageItemGenerator?.rule(rule, ...values);
            break;
        case "activation":
            if (extraArgs[0] == "enable")
                pageItemGenerator?.activateWhen(values[0][0]);
            else
                pageItemGenerator?.visibleWhen(values[0][0]);
            break;
    }
}
function asComputed(value) {
    return typeof value == "string" ? { formula: value } : value;
}

function transposeML(labels, options = {}) {
    return labels.reduce((acc, label) => pushTranslations(acc, label, options), {});
}
function pushTranslations(acc, label, options) {
    if (typeof label == "string")
        pushTranslation(acc, options.defaultLang ?? "en", label);
    else
        for (const [lang, wording] of Object.entries(label))
            if (isLanguage(options, lang))
                pushTranslation(acc, lang, wording);
    return acc;
}
function pushTranslation(acc, lang, wording) {
    acc[lang] = acc[lang] ? [...acc[lang], wording] : [wording];
}
function defaultLang(options) {
    return (options.defaultLang ?? "en");
}
function isLanguage(options, lang) {
    return (options.languages?.includes(lang) ||
        lang == options.defaultLang ||
        lang == "en" ||
        lang == "__code__");
}

function getTypeGenerator(type, options = {}) {
    type = { ...type };
    const generator = new FluentGenerator();
    switch (type.name) {
        case "scale": {
            const { name, min, max } = type;
            generator.call(name).args(min, max);
            if (type.labels)
                translateTypeLabels(generator, type, options);
            return generator;
        }
        case "score":
            generator.call(type.name).args(...type.scores);
            if (type.labels)
                translateTypeLabels(generator, type, options);
            return generator;
        case "date":
            generator
                .call("date")
                .opt(type.incomplete || undefined)
                .opt(type.month || undefined);
            return generator;
        case "choice":
        case "glossary":
            generator
                .call(type.name)
                .args(type.multiplicity, ...type.choices);
            if (type.labels && type.labels != type.choices)
                translateTypeLabels(generator, type, options);
            return generator;
        case "countries":
            generator.call(type.name).arg(type.multiplicity);
            return generator;
        case "context": {
            const { name, ...types } = type;
            generator.call(name);
            generator.arg(Object.values(types).map(t => (prefix) => getTypeGenerator(t).build(`\n      ${prefix}`, 5)));
            return generator;
        }
        case "time":
            generator.call("time").opt(type.duration || undefined);
            return generator;
        case "text":
        case "real":
        case "integer":
        case "yesno":
        case "acknowledge":
        case "image":
        case "info":
            generator.prop(type.name);
            return generator;
    }
}
function translateTypeLabels(generator, type, options) {
    const gen = new FluentGenerator();
    const { [defaultLang(options)]: def, ...others } = transposeML(type.labels, options);
    gen.call("wording").args(...def);
    for (const [lang, labels] of Object.entries(others))
        if (lang != "__code__")
            gen.call("translate").args(lang, ...labels);
    generator.compose(gen, 3, 5);
}

class PageItemGenerator {
    builder;
    options;
    typeFactory;
    generator = new FluentGenerator();
    variableName = undefined;
    constructor(builder, options = {}, typeFactory = "b.types") {
        this.builder = builder;
        this.options = options;
        this.typeFactory = typeFactory;
    }
    build() {
        return this.generator.build();
    }
    question(x, y, z, t, ...o) {
        if (this.isItemTypeLike(z) && z.name == "info" && isMLstring(x))
            return this.info(x, y);
        if (this.generator.isEmpty) {
            this.variableName =
                typeof y == "string" ? y : typeof x == "string" ? x : "";
            const { yy, zz, tt, oo } = this.mayBeTypes(y, z, t, o);
            const { [defaultLang(this.options)]: def, ...others } = transposeML(Array.isArray(x) ? x : [x], this.options);
            if (Array.isArray(x)) {
                this.multipleWordings(def, yy, zz, tt, oo);
            }
            else {
                this.singleWording(def[0], yy, zz, tt, oo);
            }
            this.translateWordings(others);
            return this;
        }
        return this.builder.question(x, y, z, t, ...o);
    }
    mayBeTypes(y, z, t, o) {
        const yy = this.mayBeType(y);
        const zz = this.mayBeType(z);
        const tt = this.mayBeType(t);
        const oo = o?.map(t => this.mayBeType(t));
        return { yy, zz, tt, oo };
    }
    mayBeType(o) {
        if (this.isItemTypeLike(o))
            return () => getTypeGenerator(o, this.options).build(this.typeFactory, 2);
        return o;
    }
    isItemTypeLike(o) {
        return typeof o == "object" && o != null && "name" in o;
    }
    singleWording(wording, yy, zz, tt, oo) {
        this.generator
            .call("question")
            .args(this.esc(wording), yy)
            .opts(zz, tt, ...oo);
    }
    multipleWordings(wordings, yy, zz, tt, oo) {
        this.generator
            .call("question")
            .args(yy, zz)
            .opts(tt, ...oo);
        this.generator.call("wordings").args(...wordings.map(w => this.esc(w)));
    }
    translateWordings(translations) {
        for (const [lang, wordings] of Object.entries(translations)) {
            this.generator
                .call("translate")
                .args(lang, ...wordings.map(w => this.esc(w)));
        }
    }
    esc(wording) {
        return wording.replace(/[\\"]/g, "\\$&");
    }
    info(wording, name) {
        if (this.generator.isEmpty) {
            this.variableName = name;
            if (isMLstring(wording)) {
                const { [defaultLang(this.options)]: def, ...others } = transposeML([wording], this.options);
                this.generator.call("info").args(this.esc(def[0]), name);
                this.translateWordings(others);
            }
            else {
                this.generator.call("info").args(wording.map(w => this.esc(w)), name);
            }
            return this;
        }
        return this.builder.info(wording, name);
    }
    include(pageName, mode) {
        return this.builder.include(pageName, mode);
    }
    startSection(title) {
        return this.builder.startSection(title);
    }
    endSection() {
        return this.builder.endSection();
    }
    unit(...units) {
        if (units.length > 0)
            this.generator.call("unit").args(...units);
        return this;
    }
    extendable() {
        const gen = new FluentGenerator().call("extendable");
        this.generator.compose(gen);
        return this;
    }
    translate(lang, translation, ...contexts) {
        this.generator.call("translate").args(lang, translation, ...contexts);
        return this;
    }
    required(formula) {
        this.generator.call("required").opt(formula);
        return this;
    }
    critical(tag, message, ...x) {
        const msg = this.unquote(isComputed(tag) ? asComputed(message) : message);
        tag = this.unquote(tag);
        this.generator
            .call("critical")
            .arg(tag)
            .opts(msg, ...x);
        return this;
    }
    unquote(v) {
        if (isComputed(v) && /^'[^']*'$/.test(v.formula))
            return v.formula.slice(1, -1);
        return v;
    }
    inRange(min, max, limits = { includeLower: true, includeUpper: true }) {
        this.generator.call("inRange").args(min, max).opt(limits);
        return this;
    }
    inPeriod(min, max, limits) {
        this.generator.call("inPeriod").args(min, max).opt(limits);
        return this;
    }
    comment(comment) {
        this.generator.call("comment").arg(comment);
        return this;
    }
    pin(title) {
        this.generator.call("pin").arg(title);
        return this;
    }
    kpi(title, pivot) {
        this.generator.call("kpi").arg(title).opt(pivot);
        return this;
    }
    maxLength(maxLength) {
        this.generator.call("maxLength").arg(maxLength);
        return this;
    }
    decimalPrecision(precision) {
        this.generator.call("decimalPrecision").arg(precision);
        return this;
    }
    fixedLength(length) {
        this.generator.call("fixedLength").arg(length);
        return this;
    }
    computed(formula) {
        this.generator.call("computed").arg(formula);
        return this;
    }
    memorize() {
        this.generator.call("memorize");
        return this;
    }
    letterCase(letterCase) {
        this.generator.call("letterCase").arg(letterCase);
        return this;
    }
    activateWhen(formula, ...values) {
        this.generator.call("activateWhen").args(formula, ...values);
        return this;
    }
    visibleWhen(formula, ...values) {
        this.generator.call("visibleWhen").args(formula, ...values);
        return this;
    }
    modifiableWhen(formula, ...values) {
        this.generator.call("modifiableWhen").args(formula, ...values);
        return this;
    }
    rule(rule, ...args) {
        const name = typeof rule == "object" ? rule.name : rule;
        const a = typeof rule == "object" ? Rules.args(rule) : args;
        switch (name) {
            case "required":
                return this.required((a[0] === true ? undefined : a[0]));
            case "critical":
                return this.critical(a[0], a[1], ...a.slice(2));
            case "inRange":
                return this.inRange(a[0], a[1], a[2]);
            case "maxLength":
                return this.maxLength(a[0]);
            case "fixedLength":
                return this.fixedLength(a[0]);
            case "decimalPrecision":
                return this.decimalPrecision(a[0]);
            case "letterCase":
                return this.letterCase(a[0]);
            case "computed":
            case "activation":
            case "constant":
            case "copy":
                this.generator.call("rule").args("dynamic", name, ...a);
                return this;
            case "dynamic":
                regenerateDynamic(this, a[0], getDynamicArgs([this.variableName], a[1])[0], ...a.slice(2));
                return this;
        }
    }
    defaultValue(defaultValue) {
        this.generator.call("defaultValue").arg(defaultValue);
        return this;
    }
    wordings(wording1, wording2, ...contexts) {
        this.generator.call("wordings").args(wording1, wording2, ...contexts);
        return this;
    }
    track() { }
}

class PageGenerator {
    builder;
    options;
    typeFactory;
    generator = new FluentGenerator();
    currentGenerator = this.generator;
    inSection = "";
    anonymousSection = " ";
    items = [];
    constructor(builder, options = {}, typeFactory = "b.types") {
        this.builder = builder;
        this.options = options;
        this.typeFactory = typeFactory;
    }
    build() {
        return this.generator.build("  ", 1);
    }
    translate(lang, translation) {
        this.currentGenerator.call("translate").args(lang, translation);
        return this;
    }
    exportTo(conf) {
        this.generator.call("exportTo").arg(conf);
        return this;
    }
    include(pageName, mode) {
        this.currentGenerator = new FluentGenerator();
        this.generator.call("include").arg(pageName).opt(mode);
        this.generator.compose(this.currentGenerator);
        return this;
    }
    select(...variableNames) {
        this.currentGenerator.call("select").args(...variableNames);
        return this;
    }
    context(variableName, ctx) {
        this.currentGenerator.call("context").args(variableName, ctx);
        return this;
    }
    startSection(title) {
        if (title) {
            const { [defaultLang(this.options)]: def, ...others } = transposeML([title], this.options);
            if (this.inSection != def[0]) {
                this.inSection = def[0];
                this.generator.call("startSection").arg(def[0]);
                this.currentGenerator = new FluentGenerator();
                this.generator.compose(this.currentGenerator);
                for (const [lang, translation] of Object.entries(others))
                    this.currentGenerator.call("translate").args(lang, translation[0]);
            }
        }
        else {
            this.startSection(this.anonymousSection);
            this.anonymousSection += " ";
        }
        return this;
    }
    endSection() {
        this.currentGenerator.call("endSection");
        this.currentGenerator = this.generator;
        this.inSection = "";
        return this;
    }
    activateWhen(variableName, ...values) {
        this.currentGenerator.call("activateWhen").args(variableName, ...values);
        return this;
    }
    visibleWhen(variableName, ...values) {
        this.currentGenerator.call("visibleWhen").args(variableName, ...values);
        return this;
    }
    modifiableWhen(variableName, ...values) {
        this.currentGenerator.call("modifiableWhen").args(variableName, ...values);
        return this;
    }
    question(x, y, z, t, ...o) {
        if (this.inSection.length == 0)
            this.currentGenerator = this.generator;
        const pageItemGenerator = new PageItemGenerator(this, this.options, this.typeFactory);
        pageItemGenerator.question(x, y, z, t, ...o);
        this.currentGenerator.compose(pageItemGenerator.generator, 0, this.inSection == "" ? 2 : 3);
        this.items.push(pageItemGenerator);
        return pageItemGenerator;
    }
    info(wording, name) {
        const pageItemGenerator = new PageItemGenerator(this, this.options, this.typeFactory);
        pageItemGenerator.info(wording, name);
        this.currentGenerator.compose(pageItemGenerator.generator, 0, 2);
        this.items.push(pageItemGenerator);
        return pageItemGenerator;
    }
    track() { }
}

class PageSetGenerator {
    builder;
    options;
    generator = new FluentGenerator();
    constructor(builder, options = {}) {
        this.builder = builder;
        this.options = options;
    }
    build() {
        return this.generator.build();
    }
    pageSet(type) {
        if (this.generator.isEmpty) {
            const { __code__, [defaultLang(this.options)]: def, ...others } = transposeML([type], this.options);
            if (typeof __code__ != "undefined") {
                this.generator.call("pageSet").arg(__code__[0]);
                this.generator
                    .call("translate")
                    .args(defaultLang(this.options), def[0]);
            }
            else
                this.generator.call("pageSet").arg(def[0]);
            for (const [lang, type] of Object.entries(others))
                this.generator.call("translate").args(lang, type[0]);
            return this;
        }
        else
            return this.builder.pageSet(type);
    }
    translate(lang, translation) {
        this.generator.call("translate").args(lang, translation);
        return this;
    }
    datevariable(datevariable) {
        this.generator.call("datevariable").arg(datevariable);
        return this;
    }
    pages(...pageDefs) {
        this.generator.call("pages").args(...pageDefs);
        return this;
    }
    track() { }
}

class LazyGenerator extends FluentGenerator {
    lazy;
    constructor(lazy) {
        super();
        this.lazy = lazy;
    }
    build(prefix, indent) {
        this.lazy();
        return super.build(prefix, indent);
    }
}
class WorkflowGenerator {
    generator = new LazyGenerator(() => this.lazy());
    _home = [];
    _seq = [];
    _n = [];
    _one = [];
    _end = [];
    _processes = [];
    _processProps = [];
    _signedPageSets = [];
    _notifications = [];
    pageSetGenerator = new FluentGenerator();
    processGenerator = new FluentGenerator();
    constructor() {
        this.generator.compose(this.pageSetGenerator, 0);
        this.generator.compose(this.processGenerator, 0);
    }
    build() {
        return this.generator.build("", 0);
    }
    lazy() {
        this.pageSetGenerator.call("home").arg(this._home[0]);
        const initial = this._seq.filter(n => !this._n.includes(n));
        if (initial.length > 0)
            this.pageSetGenerator.call("initial").args(...initial);
        const followUp = this._seq.filter(n => this._n.includes(n));
        if (followUp.length > 0)
            this.pageSetGenerator.call("followUp").args(...followUp);
        if (this._end.length > 0)
            this.pageSetGenerator.call("end").args(...this._end);
        const auxiliary = this._n.filter(n => !this._seq.includes(n));
        if (auxiliary.length > 0)
            this.pageSetGenerator.call("auxiliary").args(...auxiliary);
        this.processGenerator.call("process").arg(this._processes.join(" => "));
        const gen = new FluentGenerator();
        if (this._signedPageSets.length > 0)
            gen.call("signOn").args(...this._signedPageSets);
        for (const props of this._processProps)
            gen.call("processProps").args(...props);
        if (this._notifications.length > 0)
            gen.call("notify").args(...this._notifications);
        this.processGenerator.compose(gen);
    }
    home(name) {
        this._home.push(name);
        return this;
    }
    initial(...names) {
        this._seq.push(...names);
        return this;
    }
    followUp(...names) {
        this._seq.push(...names);
        this._n.push(...names);
        return this;
    }
    auxiliary(...names) {
        this._n.push(...names);
        return this;
    }
    end(...names) {
        this._end.push(...names);
        return this;
    }
    seq(...names) {
        this._seq.push(...names);
        return this;
    }
    n(...names) {
        this._n.push(...names);
        return this;
    }
    one(...names) {
        this._one.push(...names);
        return this;
    }
    process(actionDef, ...actions) {
        this._processes.push([actionDef, ...actions].join(" // "));
        return this;
    }
    processProps(action, ...properties) {
        if (properties.length > 0)
            this._processProps.push([action, ...properties]);
        return this;
    }
    signOn(...types) {
        this._signedPageSets.push(...types);
        return this;
    }
    notify(...events) {
        this._notifications.push(...events);
        return this;
    }
    track() { }
}
class DerivedWorkflowGenerator {
    generator = new LazyGenerator(() => this.lazy());
    pageSets = new Set();
    processes = new Set();
    notifications = new Set();
    build() {
        return this.generator.build("", 0);
    }
    lazy() {
        this.generator.call("withPageSets").args(...this.pageSets);
        this.generator.call("withProcesses").args(...this.processes);
        if (this.notifications.size > 0)
            this.generator.call("notify").args(...this.notifications);
    }
    home(name) {
        this.pageSets.add(name);
        return this;
    }
    end(...names) {
        for (const name of names)
            this.pageSets.add(name);
        return this;
    }
    seq(...names) {
        for (const name of names)
            this.pageSets.add(name);
        return this;
    }
    n(...names) {
        for (const name of names)
            this.pageSets.add(name);
        return this;
    }
    one(...names) {
        for (const name of names)
            this.pageSets.add(name);
        return this;
    }
    process(...actions) {
        for (const action of actions)
            this.processes.add(action);
        return this;
    }
    processProps() {
        return this;
    }
    signOn() {
        return this;
    }
    withPageSets(...types) {
        for (const name of types)
            this.pageSets.add(name);
        return this;
    }
    withProcesses(...actions) {
        for (const action of actions)
            this.processes.add(action);
        return this;
    }
    notify(...events) {
        for (const ev of events)
            this.notifications.add(ev);
        return this;
    }
    track() { }
}

class StudyGenerator {
    generator = new FluentGenerator();
    config = {};
    pages = [];
    build() {
        return this.generator.build("", 0);
    }
    options(options) {
        this.config = Object.assign(this.options, options);
        const gen = new FluentGenerator();
        gen.call("options").arg(() => JSON.stringify(options, null, 2));
        this.generator.compose(gen, "\nb");
    }
    strict() {
        this.generator.call("strict");
    }
    study(name) {
        const gen = new FluentGenerator();
        gen.call("study").arg(name);
        this.generator.compose(gen, "\nb");
        return this;
    }
    visit(type) {
        return this.pageSet(type);
    }
    pageSet(type) {
        const pageSetGenerator = new PageSetGenerator(this, this.config);
        this.generator.compose(pageSetGenerator.generator);
        pageSetGenerator.pageSet(type);
        return pageSetGenerator;
    }
    page(name) {
        const gen = new FluentGenerator();
        const pageGenerator = this.generatePage(gen, name);
        this.generator.compose(gen, "\nb");
        this.pages.push(pageGenerator);
        return pageGenerator;
    }
    generatePage(gen, name) {
        this.translatePageName(name, gen);
        const contentGenerator = new PageGenerator(this, this.config);
        gen.compose(contentGenerator.generator, 0);
        return contentGenerator;
    }
    translatePageName(name, gen) {
        const { __code__, [defaultLang(this.config)]: def, ...others } = transposeML([name], this.config);
        if (typeof __code__ != "undefined") {
            gen.call("page").arg(__code__[0]);
            gen.call("translate").args(defaultLang(this.config), def[0]);
        }
        else
            gen.call("page").arg(def[0]);
        for (const [lang, translation] of Object.entries(others))
            gen.call("translate").args(lang, translation[0]);
    }
    workflow(w = "main", ...names) {
        const name = typeof w == "string" || typeof w == "undefined" ? w : w.name;
        const gen = new FluentGenerator();
        if (name != "main") {
            gen.call("workflow").opts(name, ...names);
            const workflowGenerator = new DerivedWorkflowGenerator();
            gen.compose(workflowGenerator.generator, 0);
            this.generator.compose(gen, "\nb");
            return workflowGenerator;
        }
        gen.call("workflow");
        const workflowGenerator = new WorkflowGenerator();
        gen.compose(workflowGenerator.generator, 0);
        this.generator.compose(gen, "\nb");
        return workflowGenerator;
    }
    trigger(when) {
        if (when == "initialization")
            this.generator.call("trigger").arg(when);
        return this;
    }
    activateWhen(target, activator, ...values) {
        const pageItemGenerator = this.getTargetGenerator([target]);
        pageItemGenerator?.activateWhen(activator, ...values);
        return this;
    }
    visibleWhen(target, activator, ...values) {
        const pageItemGenerator = this.getTargetGenerator([target]);
        pageItemGenerator?.visibleWhen(activator, ...values);
        return this;
    }
    modifiableWhen(target, variableName, ...values) {
        const pageItemGenerator = this.getTargetGenerator([target]);
        pageItemGenerator?.modifiableWhen(variableName, ...values);
        return this;
    }
    copy(target, source) {
        const pageItemGenerator = this.getTargetGenerator([target]);
        pageItemGenerator?.defaultValue({ source });
        return new Proxy(this, {
            get: (t, p, r) => {
                if (p == "trigger")
                    return () => t;
                return Reflect.get(t, p, r);
            },
        });
    }
    computed(target, formula) {
        const pageItemGenerator = this.getTargetGenerator([target]);
        pageItemGenerator?.computed(formula);
        return new Proxy(this, {
            get: (t, p, r) => {
                if (p == "trigger")
                    return (when) => {
                        if (when == "initialization") {
                            pageItemGenerator?.generator.cancel();
                            pageItemGenerator?.defaultValue({ formula });
                        }
                        return t;
                    };
                return Reflect.get(t, p, r);
            },
        });
    }
    dynamic(variableNames, rule, values, ...extraArgs) {
        const pageItemGenerator = this.getTargetGenerator(variableNames);
        regenerateDynamic(pageItemGenerator, rule, values, ...extraArgs);
        return this;
    }
    getTargetGenerator(variableNames) {
        const target = variableNames[variableNames.length - 1];
        return this.pages.reduce((a, page) => typeof a == "undefined"
            ? page.items.find(i => i.variableName == target)
            : a, undefined);
    }
    rule(variableNames, name, ...args) {
        const rule = typeof name == "string" ? name : name.name;
        const a = typeof name == "string" ? args : Rules.args(name);
        switch (rule) {
            case "activation":
                return (a[1] == "enable" ? this.activateWhen : this.visibleWhen).call(this, variableNames[1], variableNames[0], ...a[0]);
            case "copy":
                return this.copy(variableNames[1], variableNames[0]);
            case "computed":
                return this.computed(variableNames[variableNames.length - 1], getDynamicArgs(variableNames, a)[0]);
            case "dynamic":
                return this.dynamic(variableNames, a[0], getDynamicArgs(variableNames, a[1])[0], ...a.slice(2));
            default:
                return this;
        }
    }
    track() { }
}
function generateDSL(study) {
    const json = studySerialize(study);
    const generator = new StudyGenerator();
    studyDeserialize(generator, json);
    const dsl = generator.build();
    return dslHelpers(dsl);
}
function dslHelpers(dsl) {
    return dsl
        .replace(/\{"formula":"#([0-9-]*)#"\}/g, 'b.date("$1")')
        .replace(/\{"formula":"([^"]*)"\}/g, 'b.computed("$1")')
        .replace(/\{"name":"([^"]*)","mandatory":true\}/g, 'b.mandatory("$1")')
        .replace(/b\.types\.context\(\[((.|\n)*)\]\)/, "$1");
}

class InterviewWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    save(study, patient, interview, items = interview.items, processes = interview.processes, options) {
        options = { strict: !!options?.strict };
        const pageSet = getTranslation(interview.pageSet.type, "__code__", study.options.defaultLang);
        if (interview.nonce == 0) {
            return this.createInterview(study, patient, pageSet, interview, items, processes, options);
        }
        return this.saveItems(study, patient, interview, items, processes, options);
    }
    saveItems(study, patient, interview, interviewItems, interviewProcesses, options) {
        const items = [...interviewItems.map(item => interviewItemSerialize(item))];
        const processes = [
            ...interviewProcesses.map(p => processSerialize(p, study.options)),
        ];
        const query = {
            query: "mutation ($study: String!, $patient: String!, $nonce: BigInt!, $items: [Json!]!, $processes: [Json!]!, $strict: Boolean){ saveInterview(study: $study, patient: $patient, nonce: $nonce, items: $items, processes: $processes, strict: $strict) }",
            variables: {
                study: study.name,
                patient: patient.patientCode,
                nonce: interview.nonce,
                items,
                processes,
                strict: !!options.strict,
            },
        };
        return this.client
            .post("graphql", { json: query })
            .json()
            .then(response => response.data.saveInterview)
            .catch(async (error) => await handleClientError(error));
    }
    createInterview(study, patient, pageSet, interview, interviewItems, interviewProcesses, options) {
        const items = interviewItems
            ? [...interviewItems.map(item => interviewItemSerialize(item))]
            : undefined;
        const processes = interviewProcesses
            ? [...interviewProcesses.map(p => processSerialize(p, study.options))]
            : undefined;
        const variables = {
            study: study.name,
            patient: patient.patientCode,
            pageSet,
            nonce: interview.nonce,
            strict: !!options.strict,
        };
        const query = items && process
            ? {
                query: `mutation ($study: String!, $patient: String!, $pageSet: String!, $items: [Json!], $processes: [Json!], $strict: Boolean){ createInterview(study: $study, patient: $patient, pageSet: $pageSet, items: $items, processes: $processes, strict: $strict) }`,
                variables: { ...variables, items, processes },
            }
            : processes
                ? {
                    query: `mutation ($study: String!, $patient: String!, $pageSet: String!, $processes: [Json!], $strict: Boolean){ createInterview(study: $study, patient: $patient, pageSet: $pageSet, processes: $processes, strict: $strict) }`,
                    variables: { ...variables, processes },
                }
                : items
                    ? {
                        query: `mutation ($study: String!, $patient: String!, $pageSet: String!, $items: [Json!], $strict: Boolean){ createInterview(study: $study, patient: $patient, pageSet: $pageSet, items: $items, strict: $strict) }`,
                        variables: { ...variables, items },
                    }
                    : {
                        query: `mutation ($study: String!, $patient: String!, $pageSet: String!, $strict: Boolean){ createInterview(study: $study, patient: $patient, pageSet: $pageSet, strict: $strict) }`,
                        variables,
                    };
        return this.client
            .post("graphql", { json: query })
            .json()
            .then(response => response.data.createInterview)
            .catch(async (error) => await handleClientError(error));
    }
    delete(study, patient, interview) {
        const query = {
            query: "mutation ($study: String!, $patient: String!, $nonce: BigInt!, $reason: Json!){ deleteInterview(study: $study, patient: $patient, nonce: $nonce, reason: $reason) }",
            variables: {
                study: study.name,
                patient: patient.patientCode,
                nonce: interview.nonce,
                reason: interview.__delete__,
            },
        };
        return this.client
            .post("graphql", { json: query })
            .json()
            .then(() => { })
            .catch(async (error) => await handleClientError(error));
    }
}

class SummaryWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    getPatientSummaries(study, site, x, options) {
        const select = Array.isArray(x)
            ? x
            : [
                "patientCode",
                "siteCode",
                "currentInterview",
                "interviewCount",
                "pins",
                "alerts",
                "included",
            ];
        options = Array.isArray(x) ? options : x;
        const fragment = select.join(",");
        const query = `query ($study:String!, $site:String, $offset:Int, $limit:Int){summary(study:$study, site:$site, offset:$offset, limit:$limit){${fragment}}}`;
        const variables = JSON.stringify({
            study: study.name,
            ...(site ? { site: site.siteCode } : {}),
            ...new PatientGetOptions(),
            ...options,
        });
        return this.client
            .get("graphql", { searchParams: { query, variables } })
            .json()
            .then(response => response.data.summary.map(s => {
            if (typeof s.currentInterview != "undefined" &&
                typeof s.currentInterview.date != "undefined")
                s.currentInterview.date = new Date(s.currentInterview.date);
            return s;
        }))
            .catch(async (error) => await handleClientError(error));
    }
}

class SiteWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    getAll(study) {
        const query = "query ($study:String!){sites(study:$study)}";
        const variables = JSON.stringify({ study: study.name });
        return this.client
            .get("graphql", { searchParams: { query, variables } })
            .json()
            .then(response => response.data.sites.map(s => new Site(s.siteCode, s)))
            .catch(async (error) => await handleClientError(error));
    }
    getBySiteCode(study, siteCode) {
        return this.getAll(study).then(s => s.find(e => e.siteCode == siteCode) ??
            Promise.reject([errorMessage("unknown site")]));
    }
    save(study, site) {
        const query = {
            query: "mutation ($study: String!, $site: Json!){ saveSite(study: $study, site: $site) }",
            variables: {
                study: study.name,
                site: site,
            },
        };
        return this.client
            .post("graphql", { json: query })
            .json()
            .then(response => {
            const site = response.data.saveSite;
            return new Site(site.siteCode, site);
        })
            .catch(async (error) => await handleClientError(error));
    }
}

class AuditWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    async get(study, target, operations) {
        const query = "query ($study: String!,$patient: String!,$nonce: BigInt,$variableName: String,$instance: Int, $operations:[Json!]){auditRecords(study:$study,patientCode:$patient,nonce:$nonce,variableName:$variableName,instance:$instance,operations:$operations)}";
        const variables = JSON.stringify({
            study: study.name,
            patient: target.patientCode,
            nonce: target.nonce,
            variableName: target.variableName,
            instance: target.instance,
            operations,
        });
        return this.client
            .get("graphql", { searchParams: { query, variables } })
            .json()
            .then(response => response.data.auditRecords.map(rec => Object.assign(Object.create(AuditRecord.prototype), {
            ...rec,
            date: new Date(rec.date),
        })))
            .catch(async (error) => await handleClientError(error));
    }
}

class UserWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    getAll(study) {
        const query = `admin/${study.name}/users`;
        return this.client
            .get(query)
            .json()
            .then(response => response.map(u => this.createUser(u)))
            .catch(async (error) => await handleClientError(error));
    }
    createUser(u) {
        return new User(u.name, u.firstName, u.title, u.workflow, u.email, u.phone, u.sites, u.patientIds, {
            role: u.role,
            email_verified: u.email_verfied,
            id: u.id,
            userid: u.userid,
        });
    }
    getByUserId(study, sites, userid) {
        const route = `admin/${study.name}/users/${userid}`;
        return this.client
            .get(route)
            .json()
            .then(response => this.createUser(response))
            .catch(async (error) => await handleClientError(error));
    }
    save(study, user) {
        const route = `admin/${study.name}/users/${user.userid}`;
        return this.client
            .post(route, { json: user })
            .json()
            .catch(async (error) => await handleClientError(error));
    }
}

class DocumentWebDriver {
    client;
    constructor(client) {
        this.client = client;
    }
    save(study, document) {
        const route = `document/${study.name}/${document.hash}`;
        return this.client
            .post(route, { json: document })
            .json();
    }
    delete(study, hash) {
        throw new Error(`Use delete endpoint /document/${study.name}/${hash}`);
    }
    getByHash(study, hash) {
        const query = `document/${study.name}/${hash}`;
        return this.client
            .get(query)
            .json()
            .then(d => {
            const { name, title, tags, content, ...other } = d;
            return new Document(name, title, tags, { ...other });
        });
    }
    getAll(study) {
        const query = `document/${study.name}/all`;
        return this.client
            .get(query)
            .json()
            .then(response => response.map(d => {
            const { name, title, tags, ...other } = d;
            return new Document(name, title, tags, { ...other });
        }));
    }
    saveContent(study, hash) {
        throw new Error(`Use upload endpoint /document/${study.name}/${hash}/content`);
    }
    getContent(study, hash) {
        throw new Error(`Use download endpoint /document/${study.name}/${hash}/content`);
    }
}

class ClientDrivers {
    client;
    siteDriver;
    patientDriver;
    studyDriver;
    interviewDriver;
    summaryDriver;
    auditDriver;
    userDriver;
    documentDriver;
    kpiDriver;
    constructor(client) {
        this.client = client;
        this.studyDriver = Builder.decorate(StudyWebDriver, client)
            .withLogging()
            .get();
        this.patientDriver = Builder.decorate(PatientWebDriver, client)
            .withLogging()
            .get();
        this.siteDriver = Builder.decorate(SiteWebDriver, client)
            .withLogging()
            .with(SiteCacheDriver)
            .withLogging()
            .get();
        this.interviewDriver = Builder.decorate(InterviewWebDriver, client)
            .withLogging()
            .get();
        this.summaryDriver = Builder.decorate(SummaryWebDriver, client)
            .withLogging()
            .get();
        this.auditDriver = Builder.decorate(AuditWebDriver, client)
            .withLogging()
            .get();
        this.userDriver = Builder.decorate(UserWebDriver, client)
            .withLogging()
            .get();
        this.documentDriver = Builder.decorate(DocumentWebDriver, client)
            .withLogging()
            .get();
        this.kpiDriver = Builder.decorate(KpiGenericDriver, this.siteDriver, this.summaryDriver)
            .withLogging()
            .get();
    }
}

export { AuditRecord, AuditTrail, Builder, ClientDrivers, Document, InterviewSaveOptions, KpiGenericDriver, LoggingProxy, PatientAuthorizationManager, PatientGetOptions, PatientSummary, SiteCacheDriver, SpiralClientError, SpiralError, StudyAuthorizationManager, SummaryGenericDriver, clone, crossRuleDeserialize, crossRuleSerialize, errorMessage, generateDSL, getAllTags, handleClientError, interviewDeserialize, interviewItemDeserialize, interviewItemSerialize, interviewSerialize, itemDeserialize, itemSerialize, librarySerialize, pageDeserialize, pageSerialize, pageSetDeserialize, pageSetSerialize, patientDeserialize, patientSerialize, pick, ruleDeserialize, ruleSerialize, studyDeserialize, studySerialize, workflowDeserialize, workflowSerialize };
