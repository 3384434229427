import { PatientAuthorizationManager } from "spiral-client";
import { mapGetters, mapMutations, mapActions } from "vuex";

const { pushSave, cancelSave, resumeSave, delaySave } = (function() {
  let savePending = Promise.resolve();
  let delayAction = () => {};
  const __canceled__ = { __canceled__: true };
  const __continue__ = { __canceled__: false };

  return {
    pushSave(save, onSaved) {
      const result = savePending.then(s =>
        s == __canceled__ ? __canceled__ : save()
      );
      savePending = result.catch(e => {
        console.error(e);
      });
      return result.then(s => (s == __canceled__ ? __canceled__ : onSaved?.()));
    },
    cancelSave(onCanceled = () => __canceled__) {
      delayAction(__canceled__);
      return savePending.then(r => (r == __canceled__ ? onCanceled?.() : r));
    },
    resumeSave() {
      delayAction(__continue__);
      return savePending;
    },
    delaySave(
      delay,
      onExpired = () => __continue__,
      onCanceled = () => __canceled__
    ) {
      const cancelable = new Promise(r => {
        delayAction = action => {
          r(action);
        };
      });
      const delayed = new Promise(r => {
        setTimeout(() => {
          r(__continue__);
        }, delay);
      });

      savePending = savePending.then(() => Promise.race([cancelable, delayed]));
      return savePending.then(r => {
        return r == __canceled__ ? onCanceled() : onExpired();
      });
    }
  };
})();

export default {
  computed: {
    ...mapGetters([
      "currentStudy",
      "currentPatient",
      "currentInterview",
      "currentPageSet",
      "currentPage",
      "currentUser",
      "currentWorkflow",
      "currentProcess",
      "currentErrors",
      "drivers"
    ]),
    availableInterviews() {
      return this.currentPatient.availableInterviews(this.currentWorkflow);
    },
    availablePageSets() {
      return this.currentPatient.availablePageSets(this.currentWorkflow);
    },
    epro() {
      return this.currentStudy.options?.epro;
    },
    authorizationManager() {
      return new PatientAuthorizationManager(
        this.currentStudy,
        this.currentPatient,
        this.currentUser
      );
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  methods: {
    ...mapMutations([
      "setStudy",
      "setPatient",
      "setInterview",
      "setPageSet",
      "setWorkflow",
      "setPage",
      "setUser",
      "setError",
      "clearErrors"
    ]),
    ...mapActions([
      "savePatient",
      "saveItems",
      "saveProcesses",
      "deletePatient",
      "deleteInterview"
    ]),
    pushSaveItems(items, onSaved) {
      return pushSave(() => this.saveItems(items), onSaved);
    },
    pushSaveProcesses(processes, onCanceled) {
      return pushSave(() => this.saveProcesses(processes), onCanceled);
    },
    onSaveFlushed(callback) {
      return pushSave(() => Promise.resolve(), callback);
    },
    pushSavePatient(patient, onSaved) {
      return pushSave(() => this.savePatient(patient), onSaved);
    },
    delaySave,
    cancelSave,
    resumeSave,
    async createFirstInterviewIfNotExists() {
      const first = this.currentPatient.first(this.currentWorkflow);
      if (
        this.availableInterviews.every(i => i.pageSet != first) &&
        !this.pendingStudioModification
      ) {
        this.createInterview(first);
      }
    },
    async createInterview(pageSet) {
      this.setPageSet(pageSet);
      this.setPage(undefined);
      this.setInterview(undefined);
      await this.saveItems([]);
    }
  }
};
