import store from "../store/index.js";
import { mapGetters, mapMutations } from "vuex";
import mapBind from "../mapbind.js";
import { User } from "spiral";

const {
  currentStudy,
  currentPatient,
  currentInterview,
  currentPage,
  drivers,
  setStudy,
  setPatient,
  setInterview,
  setPage,
  setUser,
  patientCode,
  role,
  givenName,
  surname,
  title,
  phone,
  email,
  email_verified,
  sites,
  patientIds,
  userid,
  id
} = mapBind(store, {
  ...mapGetters([
    "currentStudy",
    "currentPatient",
    "currentInterview",
    "currentPage",
    "drivers"
  ]),
  ...mapMutations([
    "setStudy",
    "setPatient",
    "setInterview",
    "setPage",
    "setUser"
  ]),
  ...mapGetters("auth", [
    "patientCode",
    "role",
    "givenName",
    "surname",
    "role",
    "workflow",
    "title",
    "phone",
    "email",
    "email_verified",
    "sites",
    "patientIds",
    "userid",
    "id"
  ])
});

export async function initState(to, from) {
  const params = await syncParams(
    comeBackFromAdmin(from, to) ? from.params : {},
    to.params,
    { to, from }
  );
  syncQuery(to.query);
  if (params) {
    return {
      name: to.name ?? "Home",
      params,
      query: to.query
    };
  }
}

function comeBackFromAdmin(from, to) {
  return (
    from.matched.some(m => m.name == "Admin") &&
    to.matched.every(m => m.name != "Admin")
  );
}

export function syncParams(from, to, routes) {
  return syncDiffParams(from, to, routes).then(needSync => {
    if (Object.keys(needSync).length > 0) {
      return setParams(to, needSync);
    }
  });
}

async function syncDiffParams(from, to, routes) {
  const needSync = {
    ...(await syncRootParams(from, to, routes)),
    ...(await syncPatientParams(from, to, routes))
  };
  return needSync;
}

async function syncRootParams(from, to) {
  if (to.study && to.study != from.study) {
    await syncStudy(to.study);
    await setCurrentUser();
  }
  return {};
}

async function syncPatientParams(from, to, routes) {
  if (patientCreation(routes.from) && to.patientCode)
    return { patientCode: to.patientCode };
  const patientCode = await syncPatient(to.patientCode);
  return patientForceChanged(patientCode, to) ? { patientCode } : {};
}

function patientCreation(from) {
  return from.patientCode == "create";
}

function patientForceChanged(patientCode, to) {
  return patientCode != to.patientCode;
}

async function syncStudy(name) {
  const current = currentStudy();
  if (current && name === current.name) return name;
  const study = await getByName(name);
  setStudy(study);
  return study?.name;
}

async function getByName(name) {
  return await drivers().studyDriver.getByName(name);
}

function setParams(to, params) {
  return { ...to, ...params };
}

export function syncQuery(to) {
  const visitIndex = to?.visit;
  const pageIndex = to?.page;
  const patient = currentPatient();
  const interview = patient?.interviews[visitIndex - 1];
  const page = interview?.pageSet.pages[pageIndex - 1];

  if (to.study) {
    syncStudy(to.study);
  }

  if (interview && interview != currentInterview()) {
    setInterview(interview);
  }

  if (page && page != currentPage()) {
    setPage(page);
  }
}

function syncPatient(id) {
  if (role() == "patient") return syncPatientRole(id);
  return syncPatientState(id);
}

function syncPatientRole(id) {
  if (id && id != patientCode()) return Promise.reject("Unauthorized access");
  return syncPatientState(patientCode());
}

function syncPatientState(id) {
  const patient = currentPatient();
  if (id && (!patient || id != patient.patientCode)) return initPatient(id);
  return Promise.resolve(id);
}

async function initPatient(id) {
  const sites = await drivers().siteDriver.getAll(currentStudy());
  const patient = await drivers().patientDriver.getByPatientCode(
    currentStudy(),
    sites,
    id
  );
  setPatient(patient);
  return id;
}

export async function setCurrentUser() {
  const current = currentStudy();

  if (current && sites()) {
    const allSites = await drivers().siteDriver.getAll(current);
    const sitesList =
      sites()[0] == "__all__"
        ? allSites
        : sites().map(siteCode => allSites.find(s => s.siteCode == siteCode));
    const user = new User(
      surname(),
      givenName(),
      title(),
      role(),
      email(),
      phone(),
      sitesList,
      patientIds(),
      {
        userid: userid(),
        id: id(),
        email_verified: email_verified()
      }
    );
    setUser(user);
  }
}
