import Vue from "vue";
import Vuex from "vuex";
import studio from "./modules/studio.js";
import auth from "./modules/auth.js";
import navigation from "./modules/navigation.js";
import themeConfig from "./modules/themeConfig.js";
import filters from "./modules/filters.js";
import { InterviewBuilder, PatientBuilder } from "spiral";
import { SpiralClientError } from "spiral-client";
import { Driver } from "./drivers.js";

Vue.use(Vuex);

const store = {
  state: {
    currentStudy: undefined,
    currentPageSet: undefined,
    currentPage: undefined,
    currentPatient: undefined,
    currentInterview: undefined,
    currentUser: undefined,
    currentWorkflow: undefined,
    currentErrors: []
  },
  getters: {
    currentStudy: state => state.currentStudy,
    currentPageSet: state => state.currentPageSet,
    currentPage: state => state.currentPage,
    currentPatient: state => state.currentPatient,
    currentInterview: state => state.currentInterview,
    currentUser: state => state.currentUser,
    currentErrors: state => state.currentErrors,
    currentWorkflow: state => state.currentWorkflow,
    currentProcess: state => currentProcess(state),
    drivers: (state, getters, rootState) =>
      Driver.build(rootState.auth.accessToken, rootState.studio.isStudioMode)
  },
  mutations: {
    setStudy(state, study) {
      const name = state.currentStudy?.name;
      state.currentStudy = study;
      if (study.name != name) {
        this.commit("setPageSet", study.pageSets[0]);
        this.commit("setPatient", undefined);
      }
      if (state.currentUser)
        this.commit("setWorkflow", study.workflow(state.currentUser));
      else this.commit("setWorkflow", study.mainWorkflow);
    },
    setWorkflow(state, workflow) {
      state.currentWorkflow = workflow;
    },
    setPageSet(state, pageSet) {
      if (state.currentPageSet != pageSet) {
        state.currentPageSet = pageSet;
        if (!pageSet.pages.includes(state.currentPage)) {
          this.commit("setPage", pageSet.pages[0]);
        }
      }
    },
    setPage(state, page) {
      state.currentPage = page;
    },
    setPatient(state, patient) {
      const patientCode = state.currentPatient?.patientCode;
      state.currentPatient = patient;
      if (patient?.patientCode != patientCode) {
        const workflow = state.currentWorkflow;
        this.commit("setInterview", patient?.currentInterview(workflow));
      }
    },
    setInterview(state, interview) {
      if (state.currentInterview != interview) {
        const nonce = state.currentInterview?.nonce ?? -1;
        state.currentInterview = interview;
        if (interview && nonce != 0 && interview.nonce != nonce) {
          this.commit(
            "setPageSet",
            interview?.pageSet ?? state.currentStudy.pageSets[0]
          );
          if (interview.nonce != nonce && interview.pageSet.pages.length > 0)
            this.commit(
              "setPage",
              interview?.currentPage ??
                interview?.pageSet.pages[0] ??
                state.currentStudy.pageSets[0].pages[0]
            );
          else this.commit("setPage", undefined);
        }
      }
    },
    setUser(state, user) {
      state.currentUser = user;
      this.commit("setWorkflow", state.currentStudy.workflow(user));
    },
    setError(state, error) {
      state.currentErrors.push(error);
    },
    clearErrors(state) {
      state.currentErrors = [];
    }
  },
  actions: {
    savePatient(store, patient) {
      return savePatientAsync(store, patient).catch(e =>
        handleStateError(store, e)
      );
    },
    saveItems(store, items) {
      return saveItemsAsync(store, items).catch(e =>
        handleStateError(store, e)
      );
    },
    saveProcesses(store, processes) {
      return saveProcessesAsync(store, processes).catch(e =>
        handleStateError(store, e)
      );
    },
    deletePatient(store, patient) {
      return deletePatientAsync(store, patient).catch(e =>
        handleStateError(store, e)
      );
    },
    deleteInterview(store, interview) {
      return deleteInterviewAsync(store, interview).catch(e =>
        handleStateError(store, e)
      );
    }
  },
  modules: {
    filters,
    auth,
    studio,
    navigation,
    themeConfig
  }
};

export default new Vuex.Store(store);

async function savePatientAsync({ state, commit, getters }, patient) {
  const patientKeys = await getters.drivers.patientDriver.save(
    state.currentStudy,
    patient
  );
  const newPatient = patient.update(patientKeys);
  const newInterviews = [];
  for (const i of patient.interviews) {
    const interviewKeys = await getters.drivers.interviewDriver.save(
      state.currentStudy,
      newPatient,
      i
    );
    const updated = i.update(interviewKeys);
    newInterviews.push(updated);
  }
  const updatedPatient = newPatient.update({ interviews: newInterviews });

  commit("setPatient", updatedPatient);
  commit("setInterview", newInterviews[newInterviews.length - 1]);
}

async function deletePatientAsync({ state, commit, getters }, patient) {
  await getters.drivers.patientDriver.delete(state.currentStudy, patient);
  commit("setPatient", undefined);
}

function saveItemsAsync({ state, commit, getters }, items) {
  const { builder, outer } = prepareBuild(state);
  for (const item of items) builder.item(item);
  builder.synch();
  const interview = builder.build(outer);
  return saveInterviewAsync({ state, commit, getters }, interview);
}

function saveProcessesAsync({ state, commit, getters }, processes) {
  const { builder, outer } = prepareBuild(state);
  builder.processes(processes);
  const interview = builder.build(outer);
  return saveInterviewAsync({ state, commit, getters }, interview);
}

function saveInterviewAsync({ state, commit, getters }, interview) {
  return getters.drivers.interviewDriver
    .save(state.currentStudy, state.currentPatient, interview)
    .then(interviewKeys => {
      const newInterview = interview.update(interviewKeys);
      const patient = new PatientBuilder(
        state.currentStudy,
        state.currentPatient
      )
        .interview(newInterview)
        .build();

      commit("setPatient", patient);
      commit("setInterview", newInterview);
    });
}

async function deleteInterviewAsync({ state, commit, getters }, interview) {
  await getters.drivers.interviewDriver.delete(
    state.currentStudy,
    state.currentPatient,
    interview
  );
  const newPatient = state.currentPatient.update({
    interviews: state.currentPatient.interviews.delete(
      i => i.nonce == interview.nonce
    )
  });
  commit("setPatient", newPatient);
  commit("setInterview", newPatient.interviews[0]);
}

function prepareBuild(state) {
  const builder = new InterviewBuilder(
    state.currentStudy,
    state.currentInterview ?? state.currentPageSet
  );
  const currentIndex = state.currentPatient.interviews.findIndex(
    i => i.nonce == state.currentInterview?.nonce
  );
  const outer =
    currentIndex > -1
      ? state.currentPatient.interviews.slice(0, currentIndex)
      : state.currentPatient.interviews;
  return { builder, outer };
}

function handleStateError({ commit }, error) {
  if (Array.isArray(error)) error.forEach(setError);
  else setError(error);
  return Promise.reject(error);

  function setError(err) {
    if (err instanceof SpiralClientError) commit("setError", err);
  }
}

function currentProcess(state) {
  return state.currentInterview?.currentProcess?.();
}
