export function failedLogin(to, from, next) {
  return s => {
    localStorage.setItem("spiralRedirectCallback", JSON.stringify(to));
    next({
      name: "Callback",
      query: {
        redirect_uri: "/callback",
        ...s,
        ...makeContext(to)
      }
    });
  };
}

function makeContext(to) {
  const context = {};
  if (to.params.patientCode) context.patientCode = to.params.patientCode;
  if (to.params.app) context.application = to.params.app;
  if (to.params.study) context.study = to.params.study;
  if (to.query.study) context.study = to.query.study;
  if (to.query.m) context.magic = to.query.m;
  return context;
}
