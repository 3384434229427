import i18n from "../../i18n.js";
import mixin from "../../mixin/index.js";

const m = mixin.methods;

const getters = {
  navStudyOptions: function(state, getters, rootState, rootGetters) {
    const menuOptionsLeft = [
      {
        type: "link",
        text: m.getLabel(
          "mlstring",
          {},
          { en: "Dashboard", fr: "Tableau de bord" },
          i18n
        ),
        path: { name: "Dashboard" },
        iconLeft: "<i class='mdi mdi-view-dashboard'></i>"
      },
      {
        type: "link",
        text: "Patients",
        iconLeft: "<i class='mdi mdi-folder-account'></i>",
        path: { name: "Search patient" }
      },
      {
        type: "link",
        text: m.getLabel(
          "mlstring",
          {},
          { en: "Monitoring", fr: "Monitoring" },
          i18n
        ),
        iconLeft: "<i class='mdi mdi-alert'></i>",
        path: { name: "View alerts" }
      },
      {
        type: "link",
        text: m.getLabel(
          "mlstring",
          {},
          { en: "Documents", fr: "Documents" },
          i18n
        ),
        iconLeft: "<i class='mdi mdi-book'></i>",
        path: { name: "View documents" }
      }
    ];
    const menuOptionsRight = [
      {
        type: "link",
        text: m.getLabel(
          "mlstring",
          {},
          { en: "Administration", fr: "Administration" },
          i18n
        ),
        iconLeft: "<i class='mdi mdi-cogs'></i>",
        path: { name: "Admin" },
        subMenuOptions: [
          {
            type: "link",
            text: m.getLabel(
              "mlstring",
              {},
              { en: "Study", fr: "Etude" },
              i18n
            ),
            path: { name: "Admin study" },
            iconLeft: "<i class='mdi mdi-view-dashboard'></i>"
          },
          {
            type: "link",
            text: m.getLabel(
              "mlstring",
              {},
              { en: "Sites", fr: "Centres" },
              i18n
            ),
            iconLeft: "<i class='mdi mdi-home-city'></i>",
            path: { name: "Admin sites" }
          },
          {
            type: "link",
            text: m.getLabel(
              "mlstring",
              {},
              { en: "Users", fr: "Utilisateurs" },
              i18n
            ),
            iconLeft: "<i class='mdi mdi-account-check'></i>",
            path: { name: "Admin users" }
          }
        ]
      }
    ];
    const navigationOptions = {
      elementId: "mainMenu",
      isUsingVueRouter: true,
      mobileBreakpoint: 992,
      collapseButtonImageOpen: require("@/assets/menu.svg"),
      collapseButtonOpenColor: "#661c23",
      collapseButtonCloseColor: "#661c23",
      ariaLabelMainNav: "Main Navigation",
      tooltipAnimationType: "shift-away",
      menuOptionsLeft: rootGetters["auth/isInRole"]("deo")
        ? menuOptionsLeft.slice(0, 2)
        : menuOptionsLeft,
      menuOptionsRight: rootGetters["auth/isInRole"](
        "administrator",
        "dataadministrator",
        "developer"
      )
        ? menuOptionsRight
        : undefined
    };
    return navigationOptions;
  },
  navTrackingOptions: function() {
    const menuOptionsLeft = [
      {
        type: "link",
        text: m.getLabel(
          "mlstring",
          {},
          { en: "Requirements", fr: "Exigences" },
          i18n
        ),
        path: { name: "Requirements" },
        iconLeft: "<i class='mdi mdi-clipboard-text'></i>"
      },
      {
        type: "link",
        text: m.getLabel(
          "mlstring",
          {},
          { en: "Releases", fr: "Releases" },
          i18n
        ),
        path: { name: "Releases" },
        iconLeft: "<i class='mdi mdi-archive'></i>"
      }
    ];
    return {
      elementId: "mainTrackMenu",
      isUsingVueRouter: true,
      mobileBreakpoint: 992,
      collapseButtonImageOpen: require("@/assets/menu.svg"),
      collapseButtonOpenColor: "#661c23",
      collapseButtonCloseColor: "#661c23",
      ariaLabelMainNav: "Main Navigation",
      tooltipAnimationType: "shift-away",
      menuOptionsLeft: menuOptionsLeft
    };
  }
};

export default {
  namespaced: true,
  getters
};
