import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  en: {
    domain: {
      noPatient: "Patient #",
      noSite: "Site #",
      pageSet: "Visit",
      study: "Study"
    },
    sentence: { enter: "Enter {what}" },
    action: {
      openCRF: "CRF",
      edit: "Edit",
      create: "Create",
      continue: "Continue",
      previous: "Previous",
      save: "Save",
      cancel: "Cancel"
    },
    events: {
      loading: "Loading, please wait..."
    },
    roles: {
      developer: "Developer",
      administrator: "Administrator",
      studycoordinator: "Study Coordinator",
      investigator: "Investigator",
      cst: "Clinical Study Technician (CST)",
      cra: "Clinical Research associate (CRA)",
      datamanager: "Data Manager",
      deo: "Data entry operator",
      patient: "Patient",
      dataadministrator: "Data Administrator"
    },
    studio: {
      studioMode: "STUDIO MODE"
    },
    track: {
      newIssue: "Make a request"
    }
  },
  fr: {
    domain: {
      noPatient: "N° patient",
      noSite: "N° centre",
      pageSet: "Visite",
      study: "Etude"
    },
    sentence: { enter: "Saisir {what}" },
    action: {
      openCRF: "CRF",
      edit: "Modifier",
      create: "Enregistrer",
      continue: "Continuer",
      previous: "Précédent",
      save: "Enregistrer",
      cancel: "Annuler"
    },
    events: {
      loading: "Chargement en cours, patientez svp..."
    },
    roles: {
      developer: "Développeur",
      administrator: "Administrateur",
      studycoordinator: "Coordinateur d'Etude",
      investigator: "Investigateur",
      cst: "Technicien d'étude clinique (TEC)",
      cra: "Associé Recherche Clinique (ARC)",
      datamanager: "Data Manager",
      deo: "Opérateur de saisie",
      patient: "Patient",
      dataadministrator: "Administrateur Données"
    },
    studio: {
      studioMode: "MODE STUDIO"
    },
    track: {
      newIssue: "Faire une demande"
    }
  }
};
const loc = new Intl.Locale(navigator.language);

export default new VueI18n({
  locale: loc.language,
  messages: messages,
  silentTranslationWarn: true
});
