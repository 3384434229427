import Vue from "vue";
import VueRouter from "vue-router";
import { initRedirect, authentRedirect, roleRedirect } from "./guard.js";
import { scrollBehavior } from "./scroll.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Platform Center",
    component: () => import("../views/PlatformCenter")
  },
  {
    path: "/callback",
    name: "Callback"
  },
  {
    path: "/callback-logout",
    name: "CallbackLogout"
  },
  {
    path: "/callback-renew",
    name: "CallbackRenew"
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error")
  },
  {
    path: "/login",
    name: "Login"
  },
  {
    path: "/track",
    name: "Tracking",
    component: () => import("../views/Tracking"),
    redirect: () => ({ name: "Requirements" }),
    children: [
      {
        path: "/track/requirements",
        name: "Requirements",
        component: () => import("../views/TrackRequirements")
      },
      {
        path: "/track/requirements/create",
        name: "Create issue",
        component: () => import("../views/CreateIssue")
      },
      {
        path: "/track/releases",
        name: "Releases",
        component: () => import("../views/TrackReleases")
      }
    ]
  },
  {
    path: "/:study/:app?",
    name: "Home",
    component: () => import("../views/Study"),
    children: [
      {
        path: "/:study/:app?/user",
        name: "User profile",
        component: () => import("../views/User")
      },
      {
        path: "/:study/:app?/patient/search",
        name: "Search patient",
        component: () => import("../views/PatientSearch")
      },
      {
        path: "/:study/:app?/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard")
      },
      {
        path: "/:study/:app?/patient/:patientCode/form",
        name: "Patient CRF",
        component: () => import("../views/Form")
      },
      {
        path: "/:study/:app?/patient/create/form",
        name: "Patient creation",
        component: () => import("../views/Form")
      },
      {
        path: "/:study/:app?/alert/all",
        name: "View alerts",
        component: () => import("../views/Monitoring")
      },
      {
        path: "/:study/:app?/patient/:patientCode/item/:variableName",
        name: "Item details",
        component: () => import("../components/form/ItemDetails")
      },
      {
        path: "/:study/:app?/documents",
        name: "View documents",
        component: () => import("../views/Document")
      },
      {
        path: "/:study/:app?/documents/create",
        name: "Document creation",
        component: () => import("../views/CreateDocument")
      },
      {
        path: "/:study/:app?/admin",
        name: "Admin",
        component: () => import("../views/Admin"),
        redirect: () => ({ name: "Admin users" }),
        children: [
          {
            path: "/:study/:app?/admin/users",
            name: "Admin users",
            component: () => import("../views/AdminUsers")
          },
          {
            path: "/:study/:app?/admin/users/create",
            name: "Admin create user",
            component: () => import("../views/CreateUser")
          },
          {
            path: "/:study/:app?/admin/sites",
            name: "Admin sites",
            component: () => import("../views/AdminSites")
          },
          {
            path: "/:study/:app?/admin/sites/create",
            name: "Admin create site",
            component: () => import("../views/CreateSite")
          },
          {
            path: "/:study/:app?/admin/study",
            name: "Admin study",
            component: () => import("../views/AdminStudy")
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

router.beforeEach(authentRedirect);
router.beforeEach(initRedirect);
router.beforeEach(roleRedirect);

export default router;
